import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs/internal/BehaviorSubject';
import { RequestService } from './request.service';

@Injectable( {
    providedIn: 'root',
} )
export class NoticesService {
    newMail = new BehaviorSubject<number>( 0 );
    castNewMail = this.newMail.asObservable();

    notificationsArr = new BehaviorSubject<any>( [] );
    castNotificationsArr = this.notificationsArr.asObservable();

    constructor( private requestService: RequestService ) { }

    seen_notification( notificationArr: [] ) {
        const not_id = notificationArr['not_id'];
        let notificationsArr = this.notificationsArr.getValue();
        let index = notificationsArr.indexOf( notificationArr );
        notificationArr['seen'] = 1;
        notificationsArr[index] = notificationArr;
        this.notificationsArr.next( notificationsArr );

        let paramArr = [];
        paramArr['not_id'] = notificationArr['not_id'];
        return this.requestService.notices( 'seen_notification', paramArr );
    }

    seen_notifications() {
        let notificationsArr = this.notificationsArr.getValue();
        let newNot = notificationsArr.map( ( not ) => {
            not['seen'] = 1;
            return not;
        } );
        this.notificationsArr.next( newNot );
        return this.requestService.notices( 'seen_notifications', [] );
    }

    delete_notification( notificationArr: [] ) {
        const not_id = notificationArr['not_id'];
        let notificationsArr = this.notificationsArr.getValue();
        let newNotificationsArr = notificationsArr.filter( ( noticeArr ) => {
            return noticeArr['not_id'] != not_id;
        } );

        this.notificationsArr.next( newNotificationsArr );
        let paramArr = [];
        paramArr['not_id'] = notificationArr['not_id'];
        return this.requestService.notices( 'delete_notification', paramArr );
    }

    delete_notifications() {
        this.notificationsArr.next( [] );
        return this.requestService.notices( 'delete_notifications', [] );
    }
}
