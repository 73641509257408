import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';


import { map } from 'rxjs/internal/operators/map';


import { environment } from 'src/environments/environment';
import { UserService } from './user.service';

@Injectable( {
    providedIn: 'root',
} )
export class RequestService {
    constructor( private http: HttpClient, private usersService: UserService ) { }

    signup( url: string, params: any[] ) {
        if ( localStorage.getItem( 'affLinkId' ) ) {
            params['link_id'] = localStorage.getItem( 'affLinkId' );
        }
        return this.http
            .post<[]>( environment.apiUrl + 'signup/' + url, { ...params } )
            .pipe(
                map( ( resultArr ) => {
                    return resultArr;
                } )
            );
    }

    users_simple( url: string, params: any[] ) {
        if ( this.usersService.userArr ) params['jwt_token'] = this.usersService.userArr.jwtToken;
        return this.http
            .post<[]>( environment.apiUrl + 'users/' + url, { ...params } )
            .pipe(
                map( ( resultArr ) => {
                    if ( resultArr['logout'] == true ) {
                        this.usersService.logout();
                        window.location.href = '/login';
                    }
                    return resultArr;
                } )
            );
    }

    profile( url: string, params: any[] ) {
        if ( this.usersService.userArr ) params['jwt_token'] = this.usersService.userArr.jwtToken;
        return this.http.post<[]>( environment.apiUrl + 'profile/' + url, { ...params } ).pipe(map( ( resultArr ) => {
            if ( resultArr['logout'] == true ) {
                this.usersService.logout();
                window.location.href = '/login';
            }
            return resultArr;
        }));
    }

    users( url: string, params: any[] ) {
        if ( this.usersService.userArr ) params['jwt_token'] = this.usersService.userArr.jwtToken;
        return this.http
            .post<[]>( environment.apiUrl + 'users/' + url, { ...params } )
            .pipe(
                map( ( resultArr ) => {
                    if ( resultArr['logout'] == true ) {
                        this.usersService.logout();
                        window.location.href = '/login';
                    }

                    return resultArr;
                } )
            );
    }

    rooms( url: string, params: any[] ) {
        params['jwt_token'] = this.usersService.userArr.jwtToken;
        return this.http.post<[]>(environment.apiUrl + 'rooms/' + url, { ...params } ).pipe(map( ( resultArr ) => {
            if ( resultArr['logout'] == true ) {
                this.usersService.logout();
                window.location.href = '/login';
            }
            return resultArr;
        }));
    }

    rooms_simple( url: string, params: any[] ) {
        if ( this.usersService.userArr ) params['jwt_token'] = this.usersService.userArr.jwtToken;
        return this.http
            .post<[]>( environment.apiUrl + 'rooms/' + url, { ...params } )
            .pipe(
                map( ( resultArr ) => {
                    if ( resultArr['logout'] == true ) {
                        this.usersService.logout();
                        window.location.href = '/login';
                    }
                    return resultArr;
                } )
            );
    }

    notices( url: string, params: any[] ) {
        if ( this.usersService.userArr ) params['jwt_token'] = this.usersService.userArr.jwtToken;
        return this.http
            .post<[]>( environment.apiUrl + 'notices/' + url, { ...params } )
            .pipe(
                map( ( resultArr ) => {
                    if ( resultArr['logout'] == true ) {
                        this.usersService.logout();
                        window.location.href = '/login';
                    }
                    return resultArr;
                } )
            );
    }

    notices_simple( url: string, params: any[] ) {
        return this.http
            .post<[]>( environment.apiUrl + 'notices/' + url, { ...params } )
            .pipe(
                map( ( resultArr ) => {
                    return resultArr;
                } )
            );
    }

    payment( url: string, params: any = [] ) {
        params['jwt_token'] = this.usersService.userArr.jwtToken;
        return this.http
            .post<[]>( environment.apiUrl + 'payment/' + url, { ...params } )
            .pipe(
                map( ( resultArr ) => {
                    if ( resultArr['logout'] == true ) {
                        this.usersService.logout();
                        window.location.href = '/login';
                    }
                    return resultArr;
                } )
            );
    }

    payment_simle( url: string, params: any[] ) {
        return this.http
            .post<[]>( environment.apiUrl + 'payment/' + url, { ...params } )
            .pipe(
                map( ( resultArr ) => {
                    return resultArr;
                } )
            );
    }

    mail( url: string, params: any[] ) {
        params['jwt_token'] = this.usersService.userArr.jwtToken;
        return this.http
            .post<[]>( environment.apiUrl + 'mail/' + url, { ...params } )
            .pipe(
                map( ( resultArr ) => {
                    if ( resultArr['logout'] == true ) {
                        this.usersService.logout();
                        window.location.href = '/login';
                    }
                    return resultArr;
                } )
            );
    }

    affiliates( url: string, params: any[] ) {
        return this.http
            .post<[]>( environment.apiUrl + 'affiliates/' + url, { ...params } )
            .pipe(
                map( ( resultArr ) => {
                    return resultArr;
                } )
            );
    }

    galleries( url: string, params: any[] ) {
        if ( this.usersService.userArr ) params['jwt_token'] = this.usersService.userArr.jwtToken;
        return this.http
            .post<[]>( environment.apiUrl + 'galleries/' + url, { ...params } )
            .pipe(
                map( ( resultArr ) => {
                    if ( resultArr['logout'] == true ) {
                        this.usersService.logout();
                        window.location.href = '/login';
                    }
                    return resultArr;
                } )
            );
    }

    recordings( url: string, params: any[] | FormData ) {
        if ( this.usersService.userArr ) {
          if(params instanceof FormData) {
            params.append('jwt_token', this.usersService.userArr.jwtToken);
          } else {
            params['jwt_token'] = this.usersService.userArr.jwtToken;
          }
        }
        return this.http
            .post<[]>( environment.apiUrl + 'recordings/' + url, params instanceof FormData ? params : { ...params } )
            .pipe(
                map( ( resultArr ) => {
                    if ( resultArr['logout'] == true ) {
                        this.usersService.logout();
                        window.location.href = '/login';
                    }
                    return resultArr;
                } )
            );
    }

    lush( url: string, params: any[] ) {
        if ( this.usersService.userArr ) params['jwt_token'] = this.usersService.userArr.jwtToken;
        return this.http
            .post<[]>( environment.apiUrl + 'lush/' + url, { ...params } )
            .pipe(
                map( ( resultArr ) => {
                    if ( resultArr['logout'] == true ) {
                        this.usersService.logout();
                        window.location.href = '/login';
                    }
                    return resultArr;
                } )
            );
    }

    lush_local( url: string, params: any[] ) {
        return this.http.post<[]>( url, { ...params } ).pipe(
            map((resultArr ) => {
                return resultArr;
            })
        );
    }
}
