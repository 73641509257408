import { Injectable } from '@angular/core';
import { CanLoad, Route, UrlSegment, ActivatedRouteSnapshot, RouterStateSnapshot, UrlTree, Router } from '@angular/router';
import { Observable } from 'rxjs';
import { UserService } from './user.service';

@Injectable( {
    providedIn: 'root',
} )
export class GuestGuard implements CanLoad {
    userType: number = 0;
    constructor( private userService: UserService, private router: Router ) { }

    canLoad( route: Route, segments: UrlSegment[] ): Observable<boolean> | Promise<boolean> | boolean {
        this.userService.get_login_info();
        if ( this.userService.userIsAuthentificated ) {
            this.router.navigateByUrl( '/' );
            return false;
        }
        return true;
    }
}
