import { Component, Input, OnInit, ViewChild } from '@angular/core';
import { ModalController, Platform } from '@ionic/angular';

import { VgApiService, VgMediaDirective, BitrateOptions } from '@videogular/ngx-videogular/core';
import { VgHlsDirective } from '@videogular/ngx-videogular/streaming';

import { Subscription } from 'rxjs';
import { PlatformService } from '../_services/platform.service';

@Component({
    selector: 'app-recording-modal',
    templateUrl: './recording-modal.page.html',
    styleUrls: ['./recording-modal.page.scss'],
})
export class RecordingModalPage implements OnInit {
    desktop: boolean = false;
    tablet: boolean = false;
    mobile: boolean = false;
    resizeSubscribtion: Subscription;
    showCloseButton: boolean = false;
    landscape: boolean;
    
    @Input() videoSrc: string;
    videoUrl: string = '';
    vgApi: VgApiService;
    showPlayer: boolean = false;
    
    bitrates: BitrateOptions[];
    @ViewChild( VgHlsDirective ) vgHls: VgHlsDirective;


    constructor(private modalController: ModalController, public platform: PlatformService) {}

    ngOnInit() {
        this.videoUrl = this.videoSrc;
        if (this.platform.is('mobile')) {
            this.mobile = true;
        }
        if (this.platform.is('tablet')) {
            this.tablet = true;
        }
        if (this.mobile && !this.tablet) {
            this.showCloseButton = !this.platform.isLandscape();
            this.resizeSubscribtion = this.platform.resize().subscribe(() => {
                this.showCloseButton = !this.platform.isLandscape();
            });
        }
    }
    onPlayerReady(api: VgApiService) {}

    hlsBitrates(event) {}

    setBitrate( option: BitrateOptions ) {}

    close_gallery() {
        this.modalController.dismiss();
    }

    ngOnDestroy() {
        if (this.resizeSubscribtion) {
            this.resizeSubscribtion.unsubscribe();
        }
    }
}
