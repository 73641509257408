import { Directive, Input, OnDestroy, OnInit } from '@angular/core';
import { IonCol, Platform } from '@ionic/angular';
import { Subscription } from 'rxjs';
import { PlatformService } from 'src/app/_services/platform.service';

@Directive({
    selector: '[appColSizeV1]',
})
export class ColSizeV1Directive {
    @Input('appColSizeV1') col: IonCol;
    mobile: boolean = false;
    tablet: boolean = false;
    desktop: boolean = false;
    resizeSubscribtion: Subscription;
    isLandscape: boolean = false;
    
    constructor(private platform: PlatformService) {}

    ngOnInit() {
        if (this.resizeSubscribtion) {
            this.resizeSubscribtion.unsubscribe();
        }
        if (this.platform.is('mobile')) {
            this.mobile = true;
        }
        if (this.platform.is('tablet')) {
            this.tablet = true;
        }
        if (this.platform.is('desktop')) {
            this.desktop = true;
        }
        if (this.platform.is('mobile')) {
            this.isLandscape = this.platform.isLandscape();
            this.resizeSubscribtion = this.platform.resize().subscribe(() => {
                this.isLandscape = this.platform.isLandscape();
                this.setupSize();
            });
        }
        this.setupSize();
    }

    setupSize() {
        if (this.desktop) {
            if (this.platform.width() > 1280) {
                this.col.sizeLg = '2';
            } else {
                this.col.sizeLg = '3';
            }
            this.col.sizeMd = '3';
            this.col.sizeSm = '4';
        } else {
            if (this.tablet) {
                if (this.isLandscape) {
                    this.col.size = '4';
                } else {
                    this.col.size = '6';
                }
            } else {
                if (this.isLandscape) {
                    this.col.size = '6';
                } else {
                    this.col.size = '12';
                }
            }
        }
    }

    ngOnDestroy() {
        if (this.resizeSubscribtion) {
            this.resizeSubscribtion.unsubscribe();
        }
    }
}
