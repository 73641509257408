export const project_constants = {
      videoSizesObj: {
            large:{
                  xl:8,
                  lg:8,
                  md:8
            },
            medium:{
                  xl:6,
                  lg:6,
                  md:6
            },
            small:{
                  xl:4,
                  lg:4,
                  md:4                  
            }
      },

      uploadMaxSize: 10000000,
      nrChars: 100,
      nrCharsTipMessage: 40,
      tipSuggestionsArr: [20, 40, 70, 80],
      nrCharsgoalText: 50,
      goalFireWorksSeconds: 10,

      tokenValue: 0.05,

      siteName: 'GirlsGoneGame.com',

      withdrawOptionsArr: {
            // paypal: 'Paypal',
            //iban: 'Direct deposit',
            //bitcoin: 'Bitcoin',
            //paxum: 'Paxum',
            //cec: 'Cec Payment',
            //cosmopayment: 'Cosmo Payment',
            ach: "ACH - Direct Deposit",
            wire: "International Wire Transfer"
      },

      defaultPrivatePrice: 60,
      defaultTruePrivatePrice: 90,
      defaultSpyPrice: 20,
      defaultGroupPrice: 20,

      privateMinPrice: 12,
      privateMaxPrice: 200,

      privateSpyMinPrice: 12,
      privateSpyMaxPrice: 200,

      privateTrueMinPrice: 12,
      privateTrueMaxPrice: 200,

      privateGroupMinPrice: 12,
      privateGroupMaxPrice: 200,

      buyArr: [
            {
                  package_nr: 1,
                  name: 'Beginner',
                  price: '10',
                  tokens: '100',
                  url:
                  'https://bill.ccbill.com/jpost/signup.cgi?clientAccnum=951492&clientSubacc=0043&formName=211cc&language=English&allowedTypes=0000000360:840,0000000912:840,0000001279:840&subscriptionTypeId=0000001279:840',
            },
            {
                  package_nr: 2,
                  name: 'Intermediate',
                  price: '20',
                  tokens: '200',
                  url:
                  'https://bill.ccbill.com/jpost/signup.cgi?clientAccnum=951492&clientSubacc=0043&formName=211cc&language=English&allowedTypes=0000000360:840,0000000912:840,0000001279:840&subscriptionTypeId=0000000360:840',
            },
            {
                  package_nr: 3,
                  name: 'Advanced',
                  price: '50',
                  tokens: '500',
                  url:
                  'https://bill.ccbill.com/jpost/signup.cgi?clientAccnum=951492&clientSubacc=0043&formName=211cc&language=English&allowedTypes=0000000360:840,0000000912:840,0000001279:840&subscriptionTypeId=0000000912:840',
            },
      ],
      
      zodiiArr: [, 40, 70, 80],

      
};
