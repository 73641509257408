import { Component } from '@angular/core';

import { ModalController, NavController, Platform } from '@ionic/angular';

import { UserService } from './_services/user.service';

//import { Plugins, Capacitor } from '@capacitor/core';
import { Observable } from 'rxjs/internal/Observable';
import { timer } from 'rxjs/internal/observable/timer';
import { Subscription } from 'rxjs/internal/Subscription';
import { RequestService } from './_services/request.service';
import { NoticesService } from './_services/notices.service';
import { MailService } from './_services/mail.service';
import { NavigationEnd, Router } from '@angular/router';
import { PlatformService } from './_services/platform.service';
import { environment } from 'src/environments/environment';
import { filter, map } from 'rxjs';
import { AgeVerificationModalPage } from './_components/age-verification-modal/age-verification-modal.page';
import { ObsVideoPage } from './obs-video/obs-video.page';
import { CardInputPage } from './card-input/card-input.page';

@Component({
  selector: 'app-root',
  templateUrl: 'app.component.html',
  styleUrls: ['app.component.scss'],
})
export class AppComponent {
  userName: string;
  isAuth: boolean = false;
  mobile: boolean = true;
  userType: number = 0;

  obsTimer: Observable<number> = timer(0, 60000);
  // obsTimer: Observable<number> = timer( 0, 1000 );

  streamersArr = [];
  showOnlineStreamersTitle: boolean = false;
  favStreamersArr = [];
  showFavStreamersTitle: boolean = false;
  newStreamersArr = [];
  showNewStreamersTitle: boolean = false;

  sidebarBannerArr = [];
  showSidebarBanner: boolean = false;

  timerSubs: Subscription;
  userTypeSubs: Subscription;

  loading: boolean = false;

  imagesUrl = environment.publicUrl + 'images/';
  isRequiredAccepted: any;

  constructor(
    private platform: PlatformService,

    private request: RequestService,
    private userService: UserService,
    private noticesService: NoticesService,
    private mailService: MailService,
    private router: Router,
    private modalController: ModalController,
    private navController: NavController,
  ) {
    this.initializeApp();
    this.router.events.subscribe(e => {
      // e is now NavigationEnd
      if (e instanceof NavigationEnd) {
        const ageVerification = localStorage.getItem('ageVerification');
        if (ageVerification == null) {
          this.present_age_confirmation_modal();
        }
        this.showObsVideo();
      }
    });
  }

  initializeApp() {
    this.platform.ready().then(async () => {
      this.get_sidebar_items();
      this.userService.castUserName.subscribe(async (userName) => {
        this.userService.get_login_info();

        this.isAuth = this.userService.userIsAuthentificated;
        if (this.isAuth) {
          this.userType = this.userService.userArr.userType;

        }
      });

      this.set_sidebar_items();


      if (!this.timerSubs) this.start_notification_timer();

      if (this.platform.is('desktop')) {
        this.mobile = false;
      }

    });

    this.userService.cardModalObserver.subscribe(status => {
      if(status) {
        this.goToCardInputPage();
      }
    });
  }

  async showObsVideo() {
    if (this.isAuth) {
      const user = JSON.parse(localStorage.getItem('userJson'));
      if (user?.learnobs_read == '0' && user?.userType == '2') {
        if (await this.modalController.getTop()) {
          this.modalController.dismiss();
        }
        this.modalController.create({
          component: ObsVideoPage,
          cssClass: 'obs_video_modal',
          backdropDismiss: false,
          swipeToClose: false,
        }).then((modalEl) => {
          modalEl.present();
          return modalEl.onDidDismiss();
        });
      }
    }
  }

  async present_age_confirmation_modal() {
    if (await this.modalController.getTop()) {
      this.modalController.dismiss();
    }
    console.log(this.router.url);
    if (this.router.url.startsWith('/terms')) {
      return;
    }
    this.modalController.create({
      component: AgeVerificationModalPage,
      cssClass: 'age_verification_modal',
      backdropDismiss: false,
    }).then((modalEl) => {
      modalEl.present();
      console.log('presented');

      return modalEl.onDidDismiss();
    }).then((resultObj) => {
      if (resultObj.role === 'confirm') {
        if (this.isRequiredAccepted) {
          this.navController.navigateForward('/terms');
        } else {
          this.navController.navigateForward('/terms', {
            queryParams: {
              isRequiredAccepted: true,
              originalUrl: this.router.url,
            }
          });
        }

      } else if (resultObj.role === 'dismiss') {
        window.location.href = 'https://google.com';
      }

      if (resultObj.role === 'terms') window.location.href = 'terms';
      if (resultObj.role === 'privacy_policy') window.location.href = 'terms/privacy_policy';
    });
  }


  set_sidebar_items() {
    this.userService.castStreamersArr.subscribe((streamersArr) => {
      this.test_sidebar_online(streamersArr);
      this.streamersArr = streamersArr;
    });

    this.userService.castFavStreamersArr.subscribe((favStreamersArr) => {
      this.favStreamersArr = favStreamersArr;
      if (typeof favStreamersArr !== 'undefined')
        if (favStreamersArr.length > 0) this.showFavStreamersTitle = true;
    });

    this.userService.newStreamersArr.subscribe((newStreamersArr) => {
      this.newStreamersArr = newStreamersArr;
      if (typeof newStreamersArr !== 'undefined')
        if (newStreamersArr.length > 0) this.showNewStreamersTitle = true;
    });

    this.userService.sidebarBannerArr.subscribe((sidebarBannerArr) => {
      if (typeof sidebarBannerArr !== 'undefined') {
        if (Object.keys(sidebarBannerArr).length > 0) {
          sidebarBannerArr['image_url'] = this.imagesUrl + sidebarBannerArr['file'];
          this.sidebarBannerArr = sidebarBannerArr;
          this.showSidebarBanner = true;
        }
      }
    });
  }

  start_notification_timer() {
    this.timerSubs = this.obsTimer.subscribe(() => {
      let testArr: any = [];
      testArr['user_type'] = this.userType;
      this.request.notices('get_notices', testArr).subscribe((resultArr) => {
        const url = this.router.url;
        const urlParts = url.split('/');

        if (resultArr['status'] == 0 && urlParts[1] != 'shut-down') {
          window.location.href = '/shut-down';
        }

        if (resultArr['status'] == 1 && urlParts[1] == 'shut-down') {
          window.location.href = '/';
        }

        if (resultArr['success'] == true) {
          this.noticesService.newMail.next(+resultArr['new_mail']);

          if (Object.keys(resultArr['convs_arr']).length > 0) {
            let mailsArr = Object.keys(resultArr['convs_arr']).map(function (personNamedIndex) {
              let person = resultArr['convs_arr'][personNamedIndex];
              return person;
            });
            this.mailService.mailsArr.next(mailsArr);
          }
          this.noticesService.notificationsArr.next(resultArr['notifications_arr']);
        }
      });
    });
  }

  menu_will_open() {
    if (typeof this.streamersArr !== 'undefined')
      if (this.streamersArr.length == 0) {
        this.get_sidebar_items();
      }
  }

  get_sidebar_items() {
    this.loading = true;
    let testArr: any = [];
    this.request.users_simple('get_live_streamers', testArr).subscribe((resultArr) => {

      this.loading = false;
      this.userService.streamersArr.next(resultArr['streamers_arr']);
      this.userService.favStreamersArr.next(resultArr['favorite_streamers_arr']);
      this.userService.newStreamersArr.next(resultArr['new_streamers_arr']);
      this.userService.sidebarBannerArr.next(resultArr['sidebar_banner_arr']);
    });
  }

  test_sidebar_online(streamersArr: []) {
    if (typeof streamersArr !== 'undefined')
      for (let arr of streamersArr)
        if (arr['online'] == 1) this.showOnlineStreamersTitle = true;
  }

  chat_room(userName: string) {
    this.router.navigateByUrl('/chat/' + userName);
  }

  go_to_image_url(sidebarBannerArr) {
    window.location.href = sidebarBannerArr['url'];
  }

  async goToCardInputPage() {
    const url = this.router.url.split('?')[0];
    if(!url.includes('/chat')) {
      this.router.navigate(['/card-input/1']);
    } else {
      const modalRef = await this.modalController.create({
        component: CardInputPage,
        cssClass: 'chat_buy_modal',
        componentProps: {
          price: 1,
          isModal: true
        }
      });
      modalRef.present();
    }
  }

}
