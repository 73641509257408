import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Socket } from 'ngx-socket-io';
import { BehaviorSubject } from 'rxjs';
import { map } from 'rxjs/operators';
import { environment } from 'src/environments/environment';
import { pmTxt } from '../_models/pmTxt.model';
import { Room } from '../_models/room.model';
import { Txt } from '../_models/txt.model';
import { UserService } from './user.service';

@Injectable( {
    providedIn: 'root',
} )
export class PmService {
    device: string;
    disconnected = false;

    currentRoomKey = new BehaviorSubject<string>( '' );
    castCurrentRoomKey = this.currentRoomKey.asObservable();

    roomsArr = new BehaviorSubject<Array<Room>>( [] );
    castRoomsArr = this.roomsArr.asObservable();

    textsArr = new BehaviorSubject<Array<Txt>>( [] );
    castTextsArr = this.textsArr.asObservable();

    newTexts = new BehaviorSubject<number>( 0 );
    castNewTexts = this.newTexts.asObservable();

    constructor( private socket: Socket, private usersService: UserService, private http: HttpClient ) {
        this.socket.on( 'disconnect', ( res ) => {
            this.disconnected = true;
            this.roomsArr.next( [] );
            this.textsArr.next( [] );
        } );

        this.socket.on( 'connected', () => {
            if ( this.disconnected == true ) {
                this.disconnected = false;
            }

            if ( this.roomsArr.getValue().length > 0 ) {
                this.roomsArr.next( [] );
                this.textsArr.next( [] );
            }

            this.get_rooms().subscribe( () => { } );
        } );

        this.socket.on( 'connect', ( res ) => {
            let paramsArr = {};
            paramsArr['jwt_token'] = this.usersService.userArr.jwtToken;
            paramsArr['user_name'] = this.usersService.userArr.userName;
            paramsArr['user_type'] = this.usersService.userArr.userType;
            let paramsJson = JSON.stringify( paramsArr );

            this.socket.emit( 'new_user_connect_pm', paramsJson );
        } );

        this.usersService.get_login_info();

        this.socket.on( 'generated_room_pm', ( result ) => {
            const generateArr = JSON.parse( result );
            if ( generateArr['toUserName'] == this.usersService.userArr.userName ) {
                let room = new Room( generateArr.roomKey, generateArr.fromUserName, 0, 0, 0, 1, 0, 0, generateArr.generator_avatar );
                this.add_room( room );
            }
        } );

        this.socket.on( 'receive_seen_pm', ( result ) => {
            this.update_seen( result );
        } );

        this.socket.on( 'receive_message_pm', ( result ) => {
            const receiveArr = JSON.parse( result );

            let txt = new pmTxt( receiveArr.userName, receiveArr.message, receiveArr.messageType, 0 );
            this.add_message( receiveArr.roomKey, txt );

            if ( this.currentRoomKey.getValue() != receiveArr.roomKey ) {
                this.update_room_nr( receiveArr.roomKey );
            } else {
                this.set_seen();
            }
        } );

        this.socket.on( 'user_updated_photo_pm', ( result ) => {
            this.user_updated_photo( result );
        } );
    }

    /************************* ROOMS ******************************* */
    generate_room1( toUserName: string ) {
        const testUser = {};
        testUser['jwt_token'] = this.usersService.userArr.jwtToken;
        testUser['to_user_name'] = toUserName;

        return this.http
            .post<[]>( environment.apiUrl + 'pm/generate_room', { ...testUser } )
            .pipe(
                map( ( resultArr ) => {
                    if ( resultArr['room_key'] != '' ) {
                        let profilePhoto: string = '';
                        if ( resultArr['avatar'] != '' ) profilePhoto = resultArr['avatar'];

                        let generatorAvatar: string = '';
                        if ( resultArr['generator_avatar'] != '' ) generatorAvatar = resultArr['generator_avatar'];

                        let room = new Room( resultArr['room_key'], toUserName, 0, 0, 0, 0, 0, 0, profilePhoto, resultArr['user_nr'] );
                        this.add_room( room );
                        this.generate_room( toUserName, resultArr['room_key'], profilePhoto, generatorAvatar );
                    }
                    return resultArr;
                } )
            );
    }

    generate_room( toUserName: string, roomKey: string, profilePhoto: string, generatorAvatar: string ) {
        let paramsArr = {};
        paramsArr['room_key'] = roomKey;
        paramsArr['user_name'] = this.usersService.userArr.userName;
        paramsArr['to_user_name'] = toUserName;
        paramsArr['avatar'] = profilePhoto;
        paramsArr['generator_avatar'] = generatorAvatar;

        let paramsJson = JSON.stringify( paramsArr );
        this.socket.emit( 'generate_room_pm', paramsJson );
    }

    get_rooms() {
        const testUser = {};
        testUser['jwt_token'] = this.usersService.userArr.jwtToken;

        return this.http
            .post<[]>( environment.apiUrl + 'pm/get_rooms', { ...testUser } )
            .pipe(
                map( ( result ) => {
                    if ( result['success'] == true ) {
                        let newRoomsArr = [];
                        let newTextsArr = [];
                        let newTexts = this.newTexts.getValue();

                        for ( let roomKey in result['rooms'] ) {
                            let roomArr = result['rooms'][roomKey];
                            let room = new Room(
                                roomKey,
                                roomArr.userName,
                                roomArr.meSeen,
                                roomArr.theySeen,
                                roomArr.meRemoved,
                                roomArr.theyRemoved,
                                roomArr.meBanned,
                                roomArr.theyBanned,
                                roomArr.profilePhoto,
                                roomArr.userNr
                            );
                            newRoomsArr.push( room );
                            this.socket.emit( 'join_room_pm', roomKey );

                            newTexts += +roomArr.meSeen;
                        }

                        for ( let roomKey in result['texts'] ) {
                            if ( typeof newTextsArr[roomKey] == 'undefined' ) newTextsArr[roomKey] = [];

                            let textsArr = result['texts'][roomKey];
                            for ( let txtId in textsArr ) {
                                let textArr = textsArr[txtId];
                                let txt = new pmTxt( textArr.userName, textArr.message, textArr.messageType, textArr.seen );
                                newTextsArr[roomKey].push( txt );
                            }
                        }

                        if ( newRoomsArr.length > 0 ) {
                            this.roomsArr.next( newRoomsArr );
                            this.textsArr.next( newTextsArr );
                            this.newTexts.next( newTexts );
                        }
                    }
                } )
            );
    }

    add_room( room: Room ) {
        let currentRooms = this.roomsArr.getValue();

        let found = 0;

        for ( let key in currentRooms ) {
            if ( currentRooms[key].roomKey == room.roomKey ) found = 1;
        }

        if ( found == 0 && room.roomKey != null ) {
            currentRooms.push( room );
            this.socket.emit( 'join_room_pm', room.roomKey );
            this.roomsArr.next( currentRooms );
        }
    }

    update_room_nr( roomKey: string ) {
        let newTexts = this.newTexts.getValue();
        let currentRooms = this.roomsArr.getValue();
        for ( let key in currentRooms ) {
            if ( currentRooms[key].roomKey == roomKey ) {
                newTexts++;
                currentRooms[key].meSeen++;
                currentRooms[key].theyRemoved = 0;
            }
        }
        currentRooms.sort( ( r1, r2 ) => r2.meSeen - r1.meSeen );
        
        this.newTexts.next( newTexts );
        this.roomsArr.next( currentRooms );
    }

    /************************************* TEXTS *************************************** */
    send_message( roomKey: string, toUserName: string, message: string, messageType: string ) {
        let paramsArr = {};

        paramsArr['to_user_name'] = toUserName;
        paramsArr['message'] = message;
        paramsArr['message_type'] = messageType;
        paramsArr['room_key'] = roomKey;

        let currentRooms = this.roomsArr.getValue();
        for ( let key in currentRooms ) {
            if ( currentRooms[key].roomKey == roomKey ) {
                paramsArr['userNr'] = currentRooms[key].userNr;
                paramsArr['theyRemoved'] = currentRooms[key].theyRemoved;
            }
        }

        let paramsJson = JSON.stringify( paramsArr );

        if ( messageType == 'text' ) {
            let txt = new pmTxt( this.usersService.userArr.userName, message, messageType, 0 );
            this.add_message( roomKey, txt );
        }

        if ( message.length > 0 ) {
            this.socket.emit( 'send_message_pm', paramsJson );
        }
    }

    add_message( roomKey: string, txt: pmTxt ) {
        let currentTexts = this.textsArr.getValue();

        if ( typeof currentTexts[roomKey] == 'undefined' ) {
            currentTexts[roomKey] = [];
        }
        currentTexts[roomKey].push( txt );
        this.textsArr.next( currentTexts );
    }

    set_seen() {
        const paramsArr = {};
        let currentRooms = this.roomsArr.getValue();

        let currentRoomKey = this.currentRoomKey.getValue();

        let meSeen = 0;
        for ( let key in currentRooms ) {
            if ( currentRooms[key].roomKey == currentRoomKey ) {
                paramsArr['userNr'] = currentRooms[key].userNr;
                meSeen = currentRooms[key].meSeen;
                currentRooms[key].meSeen = 0;
            }
        }

        let newTexts = this.newTexts.getValue();
        newTexts = newTexts - meSeen;
        this.newTexts.next( newTexts );

        paramsArr['room_key'] = currentRoomKey;
        let paramsJson = JSON.stringify( paramsArr );

        this.socket.emit( 'set_seen_pm', paramsJson );
    }

    update_seen( result: string ) {
        let currentTexts = this.textsArr.getValue();
        const receiveArr = JSON.parse( result );

        for ( let txtKey in currentTexts[receiveArr['room_key']] ) {
            if ( currentTexts[receiveArr['room_key']][txtKey].userName == this.usersService.userArr.userName ) {
                currentTexts[receiveArr['room_key']][txtKey].seen = 1;
            }
        }
        this.textsArr.next( currentTexts );
    }

    /************************************* PHOTOS *************************************** */
    upload_photo( roomKey: string, photo: string, photoName: string ) {
        const testUser = {};
        testUser['jwt_token'] = this.usersService.userArr.jwtToken;
        testUser['photo_name'] = photoName;
        testUser['photo'] = photo;

        return this.http
            .post<[]>( environment.apiUrl + 'pm/upload_photo', { ...testUser } )
            .pipe(
                map( ( result ) => {
                    if ( result['success'] == true ) {
                        let txt = new pmTxt( this.usersService.userArr.userName, photoName, 'photo', 0 );
                        this.add_message( roomKey, txt );
                    }
                } )
            );
    }

    /*************************** PROFILE *************************** */
    updated_profile_photo( profilePhoto: string ) {
        let paramsArr = {};
        paramsArr['profile_photo'] = profilePhoto;
        let paramsJson = JSON.stringify( paramsArr );
        this.socket.emit( 'updated_profile_photo_pm', paramsJson );
    }

    user_updated_photo( result: string ) {
        const receiveArr = JSON.parse( result );
        let currentRooms = this.roomsArr.getValue();
        for ( let key in currentRooms ) {
            if ( currentRooms[key].userName == receiveArr['userName'] ) {
                currentRooms[key].profilePhoto = receiveArr['profile_photo'];
                this.roomsArr.next( currentRooms );
            }
        }
    }

    remove_room( roomArr: Room ) {
        const testUser = {};
        testUser['jwt_token'] = this.usersService.userArr.jwtToken;
        testUser['room_arr'] = roomArr;

        return this.http
            .post<[]>( environment.apiUrl + 'pm/remove_room', { ...testUser } )
            .pipe(
                map( ( result ) => {
                    if ( result['success'] == true ) {
                        let currentRooms = this.roomsArr.getValue();
                        for ( let key in currentRooms ) {
                            if ( currentRooms[key].roomKey == roomArr['roomKey'] ) {
                                currentRooms[key].theyRemoved = 1;
                                window.location.replace( '/pm' );
                                //window.location.reload();
                            }
                        }
                        this.roomsArr.next( currentRooms );
                    }
                    return result;
                } )
            );
    }

    undo_remove_room( roomArr: Room ) {
        const testUser = {};
        testUser['jwt_token'] = this.usersService.userArr.jwtToken;
        testUser['room_arr'] = roomArr;

        return this.http
            .post<[]>( environment.apiUrl + 'pm/undo_remove_room', { ...testUser } )
            .pipe(
                map( ( result ) => {
                    if ( result['success'] == true ) {
                        let currentRooms = this.roomsArr.getValue();
                        for ( let key in currentRooms ) {
                            if ( currentRooms[key].roomKey == roomArr['roomKey'] ) {
                                currentRooms[key].theyRemoved = 0;
                                window.location.replace( '/pm/' + currentRooms[key].userName );
                            }
                        }
                        this.roomsArr.next( currentRooms );
                    }
                    return result;
                } )
            );
    }

    ban_user( roomArr: Room ) {
        let currentRooms = this.roomsArr.getValue();        

        var otherUserNr = 1;
        for ( let key in currentRooms ) {
            if ( currentRooms[key].roomKey == roomArr['roomKey'] ) {
                if ( currentRooms[key].userNr == 1 ) otherUserNr = 2;
                roomArr['otherUserNr'] = otherUserNr;
                delete currentRooms[key];
            }
        }

        let paramsJson = JSON.stringify( roomArr );
        this.socket.emit( 'ban_user_pm', paramsJson );
        window.location.replace( '/pm' );
        //this.roomsArr.next( currentRooms );
    }
}
