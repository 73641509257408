import { Component, OnInit } from '@angular/core';

import { ModalController } from '@ionic/angular';

@Component({
    selector: 'app-age-verification-modal',
    templateUrl: './age-verification-modal.page.html',
    styleUrls: ['./age-verification-modal.page.scss'],
})
export class AgeVerificationModalPage implements OnInit {
      constructor(
            private modalController: ModalController
            ) {}

      ngOnInit() {}

      modal_click(link: string){
            this.modalController.dismiss({}, link);
      }
}
