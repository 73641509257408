export class Room {
    constructor(
        public roomKey: string,
        public userName: string,        
        public meSeen: number,
        public theySeen: number,        
        public meRemoved: number,
        public theyRemoved: number,
        public meBanned: number,
        public theyBanned: number,
        public profilePhoto?: string,
        public userNr?:number
    ){}
}