import { Component, OnInit } from '@angular/core';
import { MenuController, Platform } from '@ionic/angular';
import { PlatformService } from 'src/app/_services/platform.service';

@Component({
    selector: 'app-header-guest',
    templateUrl: './header-guest.component.html',
    styleUrls: ['./header-guest.component.scss'],
})
export class HeaderGuestComponent implements OnInit {
    mobile = false;
    tablet = false;
    desktop = false;

      constructor(
        public platform: PlatformService,
        private menu: MenuController
      ) {}

    ngOnInit() {
    if (this.platform.is('mobile'))
          {this.mobile = true;}

    if (this.platform.is('tablet'))
          {this.tablet = true;}

    if (this.platform.is('desktop'))
          {this.desktop = true;}
    }

}
