import { Component, Input, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { AlertController, ModalController, ToastController } from '@ionic/angular';
import { RecordingModalPage } from 'src/app/recording-modal/recording-modal.page';
import { ChatService } from 'src/app/_services/chat.service';
import { RequestService } from 'src/app/_services/request.service';
import { UserService } from 'src/app/_services/user.service';
import { ToastService } from 'src/app/_services/toast.service';

@Component({
    selector: 'app-play-recording',
    templateUrl: './play-recording.component.html',
    styleUrls: ['./play-recording.component.scss'],
})
export class PlayRecordingComponent implements OnInit {
    @Input() recording: any;

    constructor(
        private modalController: ModalController,
        private router: Router,
        private toastController: ToastService,
        private userService: UserService,
        private alertController: AlertController,
        private requestService: RequestService,
        private chatService: ChatService
    ) { }

    ngOnInit() { }

    check_video() {
        let testArr = [];
        testArr['video_id'] = this.recording.video_id;
      console.log(this.recording);
        this.requestService.recordings('check_bought_recording', testArr).subscribe((resultArr) => {
            if (resultArr['success'] == true) {
                this.presentVideoModal(resultArr['video_src']);
            } else {
                if (resultArr['can_buy'] == true) {
                    this.alertController
                        .create({
                            header: this.recording.video_name,
                            message: 'Purchase for ' + this.recording.price + ' tokens ?',
                            buttons: [
                                {
                                    text: 'No',
                                    role: 'cancel',
                                    handler: () => { },
                                },
                                {
                                    text: 'Sure',
                                    handler: () => {
                                        if (this.userService.userArr.tokens >= +this.recording.price) {
                                            this.requestService.recordings('buy_recording', testArr).subscribe((resultArr) => {
                                                if (resultArr['success'] == true) {
                                                    let newTokens = resultArr['tokens'];
                                                    this.userService.update_tokens(newTokens);
                                                    this.chatService.refresh_my_tokens();
                                                    this.presentVideoModal(resultArr['video_src']);
                                                    this.recording.i_paid = true;
                                                    this.presentToast('Media bought successfully!');
                                                } else {
                                                    this.presentToast('This video is no longer available');
                                                }
                                            });
                                        } else {
                                            this.router.navigateByUrl('/buy');
                                        }
                                    },
                                },
                            ],
                        })
                        .then((alertEl) => {
                            alertEl.present();
                        });
                } else {
                    this.presentToast('This video is no longer available');
                }
            }
        });
    }

    presentVideoModal(video_src: string) {
        this.modalController
            .create({
                component: RecordingModalPage,
                backdropDismiss: true,
                componentProps: {
                    videoSrc: video_src,
                },
            })
            .then((modalEl) => {
                modalEl.present();
            });
    }

    presentToast(message) {
        this.toastController
            .create({
                message: message,
                duration: 2000,
                cssClass: 'toastClass',
            })
            .then((toastEl) => {
                toastEl.present();
            });
    }
}
