import { Component, OnInit, Input } from '@angular/core';
import { ModalController, Platform } from '@ionic/angular';
import { UserService } from 'src/app/_services/user.service';

import { NoticesService } from 'src/app/_services/notices.service';
import { Subscription } from 'rxjs';
import { PmService } from 'src/app/_services/pm.service';
import { Router } from '@angular/router';
import { PlatformService } from 'src/app/_services/platform.service';
import { ChatService } from 'src/app/_services/chat.service';
import { RequestService } from 'src/app/_services/request.service';
import { CardInputPage } from 'src/app/card-input/card-input.page';

@Component( {
    selector: 'app-header',
    templateUrl: './header.component.html',
    styleUrls: ['./header.component.scss'],
} )
export class HeaderComponent implements OnInit {
    @Input() pageName: string;
    @Input() backButtonUrl: string = '';

    userType: number = 0;
    model: boolean;
    newTexts: number = 0;
    pageTitle: string = '';
    pageTitleIcon: string = '';

    mobile: boolean = false;
    tablet: boolean = false;
    desktop: boolean = false;
    nrCols: number = 9;
    tokens: number = 0;
    displayTokens: string = '';
    newMail: number = 0;
    newPms: number = 0;
    newNotifications: boolean = false;
    streamerVerified: number = 1;

    nsfw:boolean;

    subsc1: Subscription;
    subsc2: Subscription;
    subsc3: Subscription;
    subsc4: Subscription;

      constructor(
            public platform: PlatformService,
            private userService: UserService,
            private noticesService: NoticesService,
            private pmService: PmService,
            private router: Router,
            private chatService: ChatService,
            private request: RequestService,
            private modalController: ModalController
      ) {
            let paramsArr = {};
            paramsArr['jwt_token'] = this.userService.userArr.jwtToken;
            let paramsJson = JSON.stringify( paramsArr );
            this.chatService.socket1.emit( 'set_jwt_token', paramsJson);

            this.chatService.socket1.on('disconnect', (res) => {
                  console.log('disconnect header');
            });

      }

      ngOnInit() {
            this.nsfw = this.userService.userArr.nsfw;
            this.userType = this.userService.userArr.userType;

            if(this.userType == 1) {
                  let paramsArr = {};
                  paramsArr['user_type'] = this.userType;
                  let paramsJson = JSON.stringify( paramsArr );
                  this.chatService.socket1.emit( 'header_refresh_tokens', paramsJson);
            }

            if ( this.userType == 2 ) {
                  this.streamerVerified = +this.userService.userArr.streamerVerified;
            }

            if ( this.platform.is( 'desktop' ) ) {
                  this.desktop = true;
                  this.nrCols = 8;
            }

            if ( this.platform.is( 'tablet' ) ) {
                  this.tablet = true;
                  this.nrCols = 8;
            }

            if ( this.platform.is( 'mobile' ) ) {
                  this.mobile = true;
                  this.nrCols = 9;
            }

            this.subsc1 = this.pmService.castNewTexts.subscribe( ( newPms ) => {
                  this.newPms = newPms;
            } );

            this.subsc2 = this.noticesService.castNewMail.subscribe( ( newMail ) => {
                  this.newMail = newMail;
            } );

            this.subsc3 = this.userService.castTokens.subscribe( ( tokens ) => {
                  this.tokens = tokens;
                  // this.formatTokens();
            } );

            this.subsc4 = this.noticesService.castNotificationsArr.subscribe( ( response ) => {
                  this.newNotifications = false;
                  if ( response.length > 0 ) {
                        if (response.find( ( notice ) => {
                              return notice['seen'] == 0;
                        })) {
                              this.newNotifications = true;
                        }
                  }
            } );
      }

    // formatTokens() { // nefolosit
    //     let number = this.tokens;

    //     if ( number == 0 ) {
    //         this.displayTokens = String( 0 );
    //     } else {
    //         if ( number <= 999 ) {
    //             this.displayTokens = String( number );
    //         } else if ( number >= 1000 && number <= 999999 ) {
    //             this.displayTokens = parseFloat( ( number / 1000 ).toFixed( 1 ) ) + 'K';
    //         } else if ( number >= 1000000 && number <= 999999999 ) {
    //             this.displayTokens = parseFloat( ( number / 1000000 ).toFixed( 1 ) ) + 'M';
    //         } else if ( number >= 1000000000 && number <= 999999999999 ) {
    //             this.displayTokens = parseFloat( ( number / 1000000000 ).toFixed( 1 ) ) + 'B';
    //         } else {
    //             this.displayTokens = String( number );
    //         }
    //         this.displayTokens = this.tokens.toString();
    //     }
    // }

    ngOnDestroy() {
        if ( this.subsc1 ) this.subsc1.unsubscribe();
        if ( this.subsc2 ) this.subsc2.unsubscribe();
        if ( this.subsc3 ) this.subsc3.unsubscribe();
    }

      gotolink(link: string) {
            if(this.pageName != 'chat') {
                  this.router.navigateByUrl('/' + link);
            } else if (link == 'home') {
                  this.router.navigateByUrl('/');
            } else {
                  if (this.userType == 2)
                        window.open('/' + link);
                  else
                        this.router.navigateByUrl('/' + link);
            }
      }

    set_nsfw() {
        if(this.nsfw == true) this.nsfw = false;
        else this.nsfw = true;

        let testArr: any = [];
        testArr['nsfw'] = this.nsfw;
        this.request.profile( 'set_nsfw', testArr ).subscribe( ( resultArr ) => {
            if(resultArr['success'] == true){
                this.userService.userArr.nsfw = this.nsfw;
                this.userService.save_login_info();
                this.userService.get_login_info();
            }
        });
    }

    async goToCardInputPage() {
      // const url = this.router.url.split('?')[0];
      // if(!url.includes('/chat')) {
      //   this.router.navigate(['/card-input/1']);
      // } else {
      //   const modalRef = await this.modalController.create({
      //     component: CardInputPage,
      //     componentProps: {
      //       price: 1,
      //       isModal: true
      //     }
      //   });
      //   modalRef.present();
      // }
      this.userService.cardModalSubject.next(true);
    }
}
