import { CurrencyPipe } from '@angular/common';
import { Component, Input, OnInit } from '@angular/core';
import { Router } from '@angular/router';
//import { ModalController } from '@ionic/angular/providers/modal-controller';
import { AlertController, ModalController, ToastController } from '@ionic/angular';
import { Subscription } from 'rxjs';
import { ChatService } from 'src/app/_services/chat.service';
import { RequestService } from 'src/app/_services/request.service';
import { ToastService } from 'src/app/_services/toast.service';
import { UserService } from 'src/app/_services/user.service';
import { project_constants } from 'src/environments/project_constants';



@Component( {
    selector: 'app-chat-tip-modal',
    templateUrl: './chat-tip-modal.page.html',
    styleUrls: ['./chat-tip-modal.page.scss'],
    providers:    [ CurrencyPipe ]
} )
export class ChatTipModalPage implements OnInit {
    @Input() userName: string;
    @Input() tokens: number;
    @Input() roomKey: string;
    @Input() profileTip: boolean;
    loading: boolean = false;
    tipSuggestionsArr = project_constants.tipSuggestionsArr;
    nrCharsTipMessage = project_constants.nrCharsTipMessage;

    tipMessage: string = '';
    customTipMessage: string = '';
    tokensSub: Subscription;
    amount: number;

    constructor(
        private currencyPipe: CurrencyPipe,
        public toastController: ToastService,
        private alertController: AlertController,
        private modalCtrl: ModalController,
        private chatService: ChatService,
        private router: Router,
        private request: RequestService,
        private usersService: UserService
    ) { }

    ngOnInit() { }

    ionViewDidEnter() {
        this.loading = false;
        this.tipMessage = '';
        this.customTipMessage = '';

        this.tokensSub = this.usersService.castTokens.subscribe( ( tokens ) => {
            this.tokens = tokens;
        } );

    }

    dismiss() {
        this.modalCtrl.dismiss( {
            dismissed: true,
        } );
    }

    visit_user_profile() {
        this.dismiss();
    }

    send_custom_tip( amount: number ) {
        if ( this.userName != '' && this.tokens != null && amount != 0 ) {
            this.request_send_tip( amount, this.customTipMessage );
        }
    }

    send_tip() {
        if ( this.userName != '' && this.tokens != null && this.amount != 0 ) {
            this.request_send_tip( this.amount, this.tipMessage );
        }
    }

    request_send_tip( amount: number, tipMessage: string ) {
        this.loading = true;
        if ( this.usersService.userArr.userType == 1 ) {
            if ( this.profileTip == true ) {
                let testArr: any = [];
                testArr['user_name'] = this.userName;
                testArr['amount'] = amount;
                testArr['tip_message'] = tipMessage;
                this.request.rooms( 'send_profile_tip', testArr ).subscribe( ( resultArr ) => {
                    if ( resultArr['success'] == true ) {
                        let newTokens = resultArr['tokens']
                        this.usersService.update_tokens( newTokens );
                        this.chatService.refresh_my_tokens();
                        this.showToastMessage(`Tip of ${amount} tokens sent successfully!`);
                    }
                    this.loading = false;
                    this.dismiss();
                } );
            } else {
                let testArr: any = [];
                testArr['user_name'] = this.userName;
                testArr['room_key'] = this.roomKey;
                testArr['amount'] = amount;
                testArr['tip_message'] = tipMessage;
                this.request.rooms( 'send_tip', testArr ).subscribe( ( resultArr ) => {
                    if ( resultArr['success'] == true ) {
                        let newTokens = resultArr['tokens']
                        this.usersService.update_tokens( newTokens );
                        this.chatService.send_tip( this.userName, this.roomKey, amount, tipMessage );
                        this.showToastMessage(`Tip of ${amount} tokens sent successfully!`);
                    }
                    this.loading = false;
                    this.dismiss();
                } );
            }
        } else {
            this.showToastMessage(`Unable to send tip`);
            this.loading = false;
            this.dismiss();
        }
    }

    buy() {
        this.dismiss();
        this.router.navigateByUrl( '/buy' );
    }

    checkAmount() {
        let amnt = String( this.amount );
        amnt = amnt.split( '.' )[0];
        this.amount = +amnt;
        this.amount = Number( this.amount );
    }

    numberOnly( event ): boolean {
        const charCode = event.which ? event.which : event.keyCode;
        if ( charCode > 31 && ( charCode < 48 || charCode > 57 ) ) {
            return false;
        }
        return true;
    }
    ionViewDidLeave() {
        if ( this.tokensSub ) this.tokensSub.unsubscribe();
    }

    async showStatusAlert(header: string, message: string, buttons = ['Ok']) {
      const alert = await this.alertController.create({
        header,
        message,
        buttons,
        cssClass: 'alert-class'
      });

      await alert.present();
    }

    async showToastMessage(message: string) {
      const toast = await this.toastController.create({
        message,
        duration: 5000,
        buttons: [
          {
            text: 'Close',
            role: 'cancel',
          }],
      });

      await toast.present();
    }
}
