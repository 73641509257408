/* eslint-disable @typescript-eslint/dot-notation */
import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { map } from 'rxjs/operators';
import { environment } from 'src/environments/environment';
import { User } from '../_models/user.model';

@Injectable( {
    providedIn: 'root',
} )
export class UserService {
    sidebarBannerArr     = new BehaviorSubject<[]>([]);
    castSidebarBannerArr = this.sidebarBannerArr.asObservable();

    streamersArr     = new BehaviorSubject<[]>([]);
    castStreamersArr = this.streamersArr.asObservable();

    favStreamersArr     = new BehaviorSubject<[]>([]);
    castFavStreamersArr = this.favStreamersArr.asObservable();

    newStreamersArr     = new BehaviorSubject<[]>([]);
    castNewStreamersArr = this.newStreamersArr.asObservable();

    userName = new BehaviorSubject<string>( '' );
    castUserName = this.userName.asObservable();

    userType = new BehaviorSubject<number>( 0 );
    castUserType = this.userType.asObservable();

    tokens = new BehaviorSubject<number>( 0 );
    castTokens = this.tokens.asObservable();

    spentTokens = new BehaviorSubject<number>( 0 );
    castSpentTokens = this.spentTokens.asObservable();

    isAuth = new BehaviorSubject<boolean>( false );
    castIsAuth = this.isAuth.asObservable();

    myTagsArr = new BehaviorSubject<any[]>([]);
    castMyTagsArr = this.myTagsArr.asObservable();

    readonly cardModalSubject = new BehaviorSubject<boolean>(false);
    readonly cardModalObserver = this.cardModalSubject.asObservable();

    // lushToysArr = new BehaviorSubject<any[]>([]);
    // castLushToysArr = this.lushToysArr.asObservable();

    public userIsAuthentificated = false;
    public userArr: User;

    public signupUserName: string = '';
    public twitterUserId: any = '';
    public signupEmail: string = '';

    constructor() {}

    save_login_info() {
        const userJson = JSON.stringify( this.userArr );
        localStorage.setItem( 'userJson', userJson );

        this.userIsAuthentificated = true;
        this.userName.next( this.userArr.userName );
        this.userType.next( this.userArr.userType );
        this.tokens.next( this.userArr.tokens );
        this.isAuth.next( true );
    }

    get_login_info() {
        const userJson = localStorage.getItem( 'userJson' );
        if ( userJson ) {
            const jsonArr = JSON.parse(userJson);
            this.userArr = new User(
                jsonArr['jwtToken'],
                jsonArr['userType'],
                jsonArr['email'],
                jsonArr['userName'],
                jsonArr['tokens'],
                jsonArr['font'],
                jsonArr['level'],
                jsonArr['showTokens'],
                jsonArr['streamerVerified'],
                jsonArr['nsfw'],
                jsonArr['roomKey'],
                jsonArr['learnobs_read'],
            );

            this.userIsAuthentificated = true;
            this.isAuth.next( true );
            this.tokens.next( jsonArr['tokens'] );
            return true;
        } else {
            return false;
        }
    }

    logout() {
        this.userName.next( '' );
        localStorage.removeItem( 'userJson' );
        localStorage.removeItem( 'push_key' );
        this.userIsAuthentificated = false;
        this.isAuth.next( false );
    }

    update_tokens( newTokens: number ) {
        this.userArr.tokens = newTokens;
        this.save_login_info();
        this.tokens.next( newTokens );
    }
}
