import { Component, Input, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { AlertController, ModalController, NavController, ToastController } from '@ionic/angular';
import { AgeVerificationModalPage } from 'src/app/_components/age-verification-modal/age-verification-modal.page';
import { ToastService } from 'src/app/_services/toast.service';
import { UserService } from 'src/app/_services/user.service';
import { TermsPage } from 'src/app/terms/terms.page';
import { social } from 'src/environments/social.model';

@Component({
  selector: 'app-footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.scss'],
})

export class FooterComponent implements OnInit {
  @Input() pageName: string;
  facebookUrl: string = social.facebookUrl;
  instagramUrl: string = social.instagramUrl;
  twitterUrl: string = social.twitterUrl;
  snapchatUrl: string = social.snapchatUrl;
  redditUrl: string = social.redditUrl;
  youtubeUrl: string = social.youtubeUrl;
  isAuth: boolean = false;

  constructor(
    private userService: UserService,
    public alertController: AlertController,
    public toastController: ToastService,
  ) { }

  ngOnInit() {
    this.isAuth = this.userService.userIsAuthentificated;

    let cookieJson = localStorage.getItem('cookieJson');
    let showCookie = localStorage.getItem('showCookieJson');

    if (cookieJson == null && showCookie == null) {
      // this.present_cookie_toast();

      let cookieArr = {};
      cookieArr['showCookie'] = true;
      let noticesJson = JSON.stringify(cookieArr);
      localStorage.setItem('showCookieJson', noticesJson);
    }
  }

  ionViewDidEnter() {
    console.log('enter')
  }

  async present_cookie_toast() {
    const toast = await this.toastController.create({
      message: 'Do you agree with our cookies policy?',
      position: 'bottom',
      color: 'dark',
      icon: 'warning-outline',

      buttons: [
        {
          text: 'No',
          handler: () => {
            window.location.href = "https://google.com";
          }
        },
        {
          text: 'Yes',
          handler: () => {
            let cookieArr = {};
            cookieArr['cookie'] = true;
            let noticesJson = JSON.stringify(cookieArr);
            localStorage.setItem('cookieJson', noticesJson);
          }
        }]
    });
    toast.present();
  }

}
