import { Injectable } from '@angular/core';
import { Platform } from '@ionic/angular';

@Injectable( {
    providedIn: 'root'
} )
export class PlatformService {
    // in app.components trebuie pus cu ready() in loc de ready
    constructor( private platform: Platform ) { }

    is( device ) {
        if ( device == 'mobile' ) {
            if ( this.platform.is( 'mobile' ) && ( !this.platform.is( 'android' ) && !this.platform.is( 'ios' ) ) ) {
                return false;
            }
        }
        if ( device == 'desktop' ) {
            if ( this.platform.is( 'mobile' ) && ( !this.platform.is( 'android' ) && !this.platform.is( 'ios' ) ) ) {
                return true;
            }
        }

        return this.platform.is( device );
    }

    resize() {
        return this.platform.resize;
    }

    ready() {
        return this.platform.ready();
    }

    width() {
        return this.platform.width();
    }

    isLandscape() {
        return this.platform.isLandscape();
    }

    isPortrait() {
        return this.platform.isPortrait();
    }

}
