import { NgModule } from '@angular/core';
import { FooterComponent } from '../footer/footer.component';
import { HeaderComponent } from '../header/header.component';
import { IonicModule } from '@ionic/angular';
import { CommonModule } from '@angular/common';
import { RouterModule } from '@angular/router';
import { HeaderGuestComponent } from '../header-guest/header-guest.component';
import { PlayRecordingComponent } from '../play-recording/play-recording.component';
import { ColSizeV1Directive } from '../directives/col-size-v1.directive';

@NgModule({
    imports: [IonicModule, CommonModule, RouterModule],
    declarations: [HeaderGuestComponent, FooterComponent, HeaderComponent, PlayRecordingComponent, ColSizeV1Directive],
    exports: [HeaderGuestComponent, FooterComponent, HeaderComponent, IonicModule, PlayRecordingComponent, ColSizeV1Directive],
})
export class SharedModule {}
