import { Injectable } from '@angular/core';
import { Socket } from 'ngx-socket-io';
import { Subscription } from 'rxjs';
import { BehaviorSubject } from 'rxjs/internal/BehaviorSubject';
import { Txt } from '../_models/txt.model';
import { RequestService } from './request.service';
import { UserService } from './user.service';

@Injectable( {
    providedIn: 'root',
} )
export class ChatService {
    disconnected = false;

    textsArr = new BehaviorSubject<Array<Txt>>( [] );
    castTextsArr = this.textsArr.asObservable();

    userCount = new BehaviorSubject<number>( 0 );
    castUserCount = this.userCount.asObservable();

    usersList = new BehaviorSubject<Array<any>>( [] );
    castUsersList = this.usersList.asObservable();


    goalArr = new BehaviorSubject<Array<any>>( [] );
    castGoalArr = this.goalArr.asObservable();

    goalReached = new BehaviorSubject<boolean>( false );
    castGoalReached = this.goalReached.asObservable();


    moderator = new BehaviorSubject<string>( '' );
    castModerator = this.moderator.asObservable();

    roomKey = new BehaviorSubject<string>( '' );
    castRoomKey = this.roomKey.asObservable();

    privateRoomKey = new BehaviorSubject<string>( '' );
    castPrivateRoomKey = this.privateRoomKey.asObservable();

    iSpy = new BehaviorSubject<boolean>( false );
    castiSpy = this.iSpy.asObservable();

    broadcastStopped = new BehaviorSubject<boolean>( false );
    castiBroadcastStopped = this.broadcastStopped.asObservable();

    /*
    se refera la statusul tau ca membru sau model, in ce forma est in privat:
    1 - private
    2 - true private
    3 - group private
    4 - spy in private
    5 - admin in private
    6 - away (doar pt modele)
    */
    inPrivate = new BehaviorSubject<number>( 0 );
    castInPrivate = this.inPrivate.asObservable();

    /*
    se refera la statusul modelului pe care il percep membrii sau adminul
    1 - in private
    2 - true private
    3 - group private
    6 - away - nu se poate da spy sau join
    */

    modelInPrivate = new BehaviorSubject<number>( 0 );
    castModelInPrivate = this.modelInPrivate.asObservable();

    modelInTruePrivate = new BehaviorSubject<boolean>( false );
    castModelInTruePrivate = this.modelInTruePrivate.asObservable();

    modelInGroupPrivate = new BehaviorSubject<boolean>( false );
    castModelInGroupPrivate = this.modelInGroupPrivate.asObservable();

    privateTextsArr = new BehaviorSubject<Array<Txt>>( [] );
    castPrivateTextsArr = this.privateTextsArr.asObservable();

    socketDisconected = new BehaviorSubject<boolean>( false );
    castSocketDisconected = this.socketDisconected.asObservable();

    allowGroupRequest = new BehaviorSubject<boolean>( true );
    castAllowGroupRequest = this.allowGroupRequest.asObservable();

    joinGroupButtonDisabled = new BehaviorSubject<boolean>( false );
    castJoinGroupButtonDisabled = this.joinGroupButtonDisabled.asObservable();

    roomPrices = new BehaviorSubject<Array<any>>( [] );
    castRoomPrices = this.roomPrices.asObservable();

    isAuthSub: Subscription;

    userType: number = 0;
    myUserName: string = '';
    modelUserMame: string = '';
    //moderator: string = '';

    friendsArr = [];
    mutesArr = [];

    socket1: Socket;

      constructor(
            private socket: Socket,
            private usersService: UserService,
            private requestService: RequestService
      ) {
            this.socket1 = this.socket;
            this.isAuthSub = this.usersService.castIsAuth.subscribe( ( val ) => {
                  if ( val ) {
                        this.userType = this.usersService.userArr.userType;
                  } else {
                        this.userType = 0;
                  }
            } )

            this.socket.on( 'stop_stream', ( res ) => {
                  window.location.href = '/';
            } );

            this.socket.on( 'shut_down', ( res ) => {
                  this.leave_private();
                  window.location.href = '/shut-down';
            } );

            this.connect_to_server();

            this.socket.on( 'disconnect', ( res ) => {
                  this.socketDisconected.next( true );
                  this.disconnected = true;
                  this.connect_to_server();
            } );

            this.socket.on('connected', () => {
                  // console.log('connected');

                  this.connect_to_server();
            });

            this.socket.on( 'receive_message', ( result ) => {
                  const receiveArr = JSON.parse( result );
                  let txt = new Txt( receiveArr['userName'], receiveArr['message'], receiveArr['messageType'], receiveArr['font'], receiveArr['color'] );
                  this.add_message( txt );
            } );

            this.socket.on( 'receive_user_count', ( result ) => {
                  const receiveArr = JSON.parse( result );
                  if ( receiveArr['user_count'] != undefined ) this.userCount.next( receiveArr['user_count'] );
            } );

            this.socket.on( 'receive_users_list', ( result ) => {
                  let receiveArr = JSON.parse( result );

                  if ( receiveArr['users_list'] != undefined ) {
                        let usersListObj = JSON.parse( receiveArr['users_list'] );

                        let usersListArr = [];
                        for ( let key in usersListObj ) {
                              usersListObj[key]['moderator'] = 0;
                              usersListArr.push( usersListObj[key] );
                        }
                        this.usersList.next( usersListArr );
                  }
            } );

            this.socket.on( 'update_room_prices', ( result ) => {
                  const receiveArr = JSON.parse( result );
                  this.roomPrices.next( receiveArr['prices'] );

            } )

            this.socket.on( 'moderator_change', ( result ) => {
                  const receiveArr = JSON.parse( result );
                  if ( this.myUserName != '' ) {
                        if ( this.myUserName == this.moderator.getValue() ) {
                              if ( receiveArr['user_name'] != this.myUserName ) {
                              window.location.reload();
                              }
                        } else {
                              if ( receiveArr['user_name'] == this.myUserName ) {
                              window.location.reload();
                              }
                        }
                  }
            });

            this.socket.on( 'ban', ( result ) => {
                  const receiveArr = JSON.parse( result );
                  if ( this.myUserName == receiveArr['user_name'] ) {
                        window.location.reload();
                  }
            });

            this.socket.on( 'update_goal', ( result ) => {
                  const receiveArr = JSON.parse( result );
                  if ( receiveArr['goal_text'] != '' && receiveArr['amount'] > 0 ) {
                      let goalArr = [];
                      goalArr['goalText'] = receiveArr['goal_text'];
                      goalArr['amount'] = receiveArr['amount'];
                      goalArr['amountLeft'] = receiveArr['amount_left'];
                      this.goalArr.next( goalArr );
                  }
            });

            this.socket.on( 'goal_reached', () => {
                  this.goalReached.next( true );
            } );

            /************* SOCKET PRIVATE****************** */
              this.socket.on( 'join_group_expired', ( result ) => {
                  let currentValue = this.textsArr.value;
                  let newCurrentValue = currentValue.filter( function ( sa ) {
                      return sa.messageType != 'group_request' && sa.messageType != 'group_join' && sa.messageType != 'group_expired';
                  });

                  this.textsArr.next( newCurrentValue );

                  let message = 'Group request expired.';
                  let messageType = 'group_expired';
                  let txt = new Txt( '', message, messageType, '', this.myUserName );
                  this.add_message( txt );
                  this.allowGroupRequest.next( true );
                  this.joinGroupButtonDisabled.next( false );
              })

              this.socket.on( 'join_group_requested', ( result ) => {
                  const receiveArr = JSON.parse( result );
                  let requestType = '';
                  let message = '';
                  if ( receiveArr['group_count'] > 1 ) {
                      if ( this.myUserName == receiveArr['user_name'] ) {
                          requestType = 'group_request';
                          message = 'You initiated a GROUP UP request';
                          this.allowGroupRequest.next( false );
                      } else {
                          if ( receiveArr['group_users'].includes( this.myUserName ) ) {
                              requestType = 'group_request';
                              this.allowGroupRequest.next( false );
                          } else {
                              if ( this.userType == 2 ) {
                                  requestType = 'group_request';
                              } else {
                                  requestType = 'group_join';
                              }
                          }
                          message = receiveArr['group_count'] + ' members are now in GROUP UP request';
                      }
                  } else {
                      requestType = 'group_join';
                      if ( this.myUserName == receiveArr['user_name'] ) {
                          this.allowGroupRequest.next( false );
                          message = 'You initiated a GROUP request';
                      } else {
                          this.allowGroupRequest.next( true );
                          message = receiveArr['user_name'] + ' initiated a GROUP UP request';
                      }
                  }

                  if ( message && requestType ) {
                      let txt = new Txt( receiveArr['user_name'], message, requestType, '', this.myUserName, receiveArr['group_users'] );
                      this.add_message( txt );
                  }
              } )

              this.socket.on( 'join_group_updated', ( result ) => {
                  const receiveArr = JSON.parse( result );
                  let currentValue = this.textsArr.value;
                  let newCurrentValue = currentValue.filter( function ( sa ) {
                      return sa.messageType != 'group_request' && sa.messageType != 'group_join' && sa.messageType != 'group_expired';
                  } );
                  this.textsArr.next( newCurrentValue );

                  let messageType = '';
                  let message = '';

                  if ( receiveArr['group_users'].includes( this.myUserName ) ) {
                      messageType = 'group_request';
                      message = receiveArr['group_count'] + ' members are now in GROUP UP request';
                      this.allowGroupRequest.next( false );
                  } else {
                      message = receiveArr['user_name'] + ' initiated a GROUP UP request';
                      messageType = 'group_join';
                  }
                  if ( message && messageType ) {
                      let txt = new Txt( receiveArr['user_name'], message, messageType, '', this.myUserName, receiveArr['group_users'] );
                      this.add_message( txt );
                  }
              } )

              this.socket.on( 'receive_private_request', ( result ) => {
                  const receiveArr = JSON.parse( result );

                  if ( receiveArr['room_key'] != undefined && receiveArr['user_name'] != undefined && receiveArr['model_name'] != undefined && receiveArr['private_type'] != undefined ) {

                      let privateType = receiveArr['private_type'];

                      // nu afiseaza cererea de privat de mai multe ori
                      let currentTexts = this.textsArr.value;
                      let textsLength = currentTexts.length;
                      let privateRequested: number = 0;
                      if ( ( privateType == 1 || privateType == 2 ) && receiveArr['model_name'] == this.myUserName ) {
                          for ( let textId in currentTexts ) {
                              let textArr = currentTexts[textId];
                              if ( receiveArr['user_name'] == textArr.userName && ( textArr.messageType == 'private_request' || textArr.messageType == 'true_private_request' ) && textsLength - +textId < 5 ) {
                                  privateRequested = 1;
                              }
                          }
                      }

                      if ( privateRequested ) {
                          return false;
                      }

                      let message = '';
                      let requestType = '';
                      if ( this.myUserName == receiveArr['model_name'] && this.userType == 2 ) {
                          if ( privateType == 1 ) {
                              message = receiveArr['user_name'] + ' sent you a CO-OP request';
                              requestType = 'private_request';
                          }
                          if ( privateType == 2 ) {
                              requestType = 'true_private_request';
                              message = receiveArr['user_name'] + ' sent you a 1V1 request';
                          }
                          if ( privateType == 3 ) {
                              if ( receiveArr['group_count'] > 1 ) {
                                  requestType = 'group_request';
                                  message = receiveArr['group_count'] + ' members are now in GROUP UP request';
                              } else {
                                  requestType = 'group_join';
                                  message = receiveArr['user_name'] + ' initiated a GROUP UP request';
                              }
                          }

                      } else if ( this.userType == 1 ) {
                          if ( privateType == 1 || privateType == 2 ) {
                              if ( this.myUserName == receiveArr['user_name'] ) {
                                  if ( privateType == 1 ) {
                                      requestType = 'private_request';
                                      message = 'CO-OP request sent to ' + receiveArr['model_name'];
                                  } else {
                                      requestType = 'true_private_request';
                                      message = '1V1 request sent to ' + receiveArr['model_name'];
                                  }
                              }
                          } else if ( privateType == 3 ) {
                              if ( receiveArr['group_count'] > 1 ) {
                                  if ( this.myUserName == receiveArr['user_name'] ) {
                                      message = 'You joined the GROUP UP request';
                                  } else {
                                      message = receiveArr['group_count'] + ' members are now in GROUP UP request';
                                  }
                                  if ( receiveArr['group_users'].includes( this.myUserName ) ) {
                                      requestType = 'group_request';
                                  } else {
                                      requestType = 'group_join';
                                  }
                              } else {
                                  requestType = 'group_join';
                                  if ( this.myUserName == receiveArr['user_name'] ) {
                                      message = 'You initiated a GROUP UP request';
                                  } else {
                                      message = receiveArr['user_name'] + ' initiated a GROUP UP request';
                                  }
                              }
                          }
                      }

                      if ( privateType == 3 ) {
                          let currentValue = this.textsArr.value;
                          let newCurrentValue = currentValue.filter( function ( sa ) {
                              return sa.messageType != 'group_request' && sa.messageType != 'group_join' && sa.messageType != 'group_expired';
                          } );
                          this.textsArr.next( newCurrentValue );
                      }

                      if ( message != '' ) {
                          let txt = new Txt( receiveArr['user_name'], message, requestType, '', this.myUserName, receiveArr['group_users'] );
                          this.add_message( txt );
                      }
                  }
              } );

              this.socket.on( 'private_accepted', ( result ) => {
                  const receiveArr = JSON.parse( result );

                  if ( receiveArr['model_name'] != undefined && receiveArr['private_room_key'] != undefined && receiveArr['room_key'] != undefined ) {
                      if ( this.userType > 0 ) {
                          this.privateRoomKey.next( receiveArr['private_room_key'] );
                          if ( receiveArr['room_type'] == 'private' ) {
                              if ( this.myUserName == receiveArr['model_name'] ) {
                                  this.inPrivate.next( 1 );
                                  this.join_private();
                              } else {
                                  if ( this.myUserName == receiveArr['user_name'] ) {
                                      this.inPrivate.next( 1 );
                                      this.join_private();
                                  }
                              }
                          }
                          if ( receiveArr['room_type'] == 'true_private' ) {
                              if ( this.myUserName == receiveArr['model_name'] ) {
                                  this.inPrivate.next( 2 );
                                  this.join_private();
                              } else {
                                  if ( this.myUserName == receiveArr['user_name'] ) {
                                      this.inPrivate.next( 2 );
                                      this.join_private();
                                  }
                              }
                          }
                          if ( receiveArr['room_type'] == 'group' ) {
                              if ( this.myUserName == receiveArr['model_name'] ) {
                                  this.inPrivate.next( 3 );
                                  this.join_private();
                              } else {
                                  if ( receiveArr['group_users'].includes( this.myUserName ) ) {
                                      this.inPrivate.next( 3 );
                                      this.join_private();
                                  }
                              }
                          }
                      }

                      if ( receiveArr['room_type'] == 'private' ) {
                          this.modelInPrivate.next( 1 );
                      }
                      if ( receiveArr['room_type'] == 'true_private' ) {
                          this.modelInPrivate.next( 2 );
                      }
                      if ( receiveArr['room_type'] == 'group' ) {
                          this.modelInPrivate.next( 3 );
                      }
                  }
                  if ( receiveArr['room_type'] == 'group' ) {
                      let currentValue = this.textsArr.value;
                      let newCurrentValue = currentValue.filter( function ( sa ) {
                          return sa.messageType != 'group_request' && sa.messageType != 'group_join' && sa.messageType != 'group_expired';
                      } );
                      this.textsArr.next( newCurrentValue );
                  }
              } );

              this.socket.on( 'leave_private', ( result ) => {
                  window.location.reload();
              } );

              this.socket.on( 'model_back_live', ( result ) => {
                  this.modelInPrivate.next( 0 );
                  this.inPrivate.next( 0 );
              } );


              this.socket.on( 'mmdel_private_empty', ( result ) => {
                  this.modelInPrivate.next( 6 );
                  this.inPrivate.next( 6 );
              } )

              this.socket.on( 'private_empty', ( result ) => {
                  this.modelInPrivate.next( 6 );
              } );

              this.socket.on( 'model_left_private', ( result ) => {
                  const receiveArr = JSON.parse( result );

                  this.socket.emit( 'stop_counter', {} );

                  let message = receiveArr['model_name'] + ' left the private room';
                  let txt = new Txt( receiveArr['user_name'], message, 'private_leave', '', this.myUserName );
                  this.add_message( txt );

                  window.location.reload();
              } );

              this.socket.on( 'user_left_private_room', ( result ) => {
                  if ( this.userType == 2 ) {
                      const receiveArr = JSON.parse( result );

                      let message = receiveArr['user_name'] + ' left the private room';
                      let txt = new Txt( receiveArr['user_name'], message, 'private_leave', '', this.myUserName );
                      this.add_message( txt );
                  }
              } );


              this.socket.on( 'user_left_room', ( result ) => {
                  if ( this.userType == 2 ) {
                      const receiveArr = JSON.parse( result );

                      let currentValue = this.textsArr.value;
                      let newCurrentValue = currentValue.filter( function ( sa ) {
                          if ( sa.userName == receiveArr['user_name'] && ( sa.messageType == 'true_private_request' || sa.messageType == 'private_request' ) ) {
                              return false;
                          } else {
                              return true;
                          }
                      } );
                      this.textsArr.next( newCurrentValue );
                  }
              } );

              this.socket.on( 'update_tokens', ( result ) => {
                  if ( this.userType == 1 ) {
                      const receiveArr = JSON.parse( result );
                      this.usersService.update_tokens( receiveArr['tokens'] );
                  }
              } );

              this.socket.on( 'refresh_user_tokens', ( result ) => {
                  if (this.userType == 1) {
                      const receiveArr = JSON.parse(result);
                      if (receiveArr['user_name'] == this.usersService.userArr['userName']) {
                          this.usersService.update_tokens(receiveArr['tokens']);
                      }

                  }
              } );

              this.socket.on( 'force_leave', ( result ) => {
                  if ( this.userType == 1 ) {
                      window.location.reload();
                  }
              } );

              this.socket.on( 'rejected_private', ( result ) => {
                  if ( this.userType == 1 ) {
                      const receiveArr = JSON.parse( result );
                      if ( this.myUserName == receiveArr['user_name'] ) {
                          let message = receiveArr['model_name'] + ' rejected the private request';
                          let txt = new Txt( receiveArr['user_name'], message, 'private_rejected', '', this.myUserName );
                          this.add_message( txt );
                      }
                  }
              } );

                  /************* SOCKET BROADCAST ****************** */
                  this.socket.on( 'stop_broadcast', ( result ) => {
                        this.broadcastStopped.next( true );
                  } );

                  this.socket.on( 'start_broadcast', ( result ) => {
                        this.broadcastStopped.next( false );
                  } );

                  /************* LOGOUT ****************** */
                  this.socket.on( 'logout', ( result ) => {
                        this.usersService.logout();
                        window.location.href = '/login';
                        this.socket1.disconnect(true);
                        this.socket.disconnect(true);
                  } );

    }


      /***************************** CONNECTION ******************************************/
      connect_to_server() {
            this.socketDisconected.next( false );
            this.socket
            console.log('Connecting to server');
            if ( this.disconnected == true ) {
                  // console.log('atentie aici pentru ca s-ar putea sa fie problema la join room daca nu esti intr-o camera');
                  this.disconnected = false;
                  this.connect_user();
                  this.join_room();
            }
      }

      connect_user( modelUserMame = '' ) {
            if ( modelUserMame ) {
                  this.modelUserMame = modelUserMame;
            }

            let paramsArr = {};
            paramsArr['user_type'] = this.userType;
            paramsArr['model_name'] = this.modelUserMame;

            if ( this.userType != 0 ) {
                  paramsArr['jwt_token'] = this.usersService.userArr.jwtToken;
                  paramsArr['user_name'] = this.usersService.userArr.userName;
                  paramsArr['font'] = this.usersService.userArr.font || 'font_prime';
                  paramsArr['tokens'] = this.usersService.userArr.tokens;
                  paramsArr['level'] = this.usersService.userArr.level;
                  paramsArr['show_tokens'] = this.usersService.userArr.showTokens;

                  this.myUserName = this.usersService.userArr.userName;

                  let paramsJson = JSON.stringify( paramsArr );
                  this.socket.emit( 'new_user_connect', paramsJson );
            } else {
                  let paramsJson = JSON.stringify( paramsArr );
                  this.socket.emit('new_guest_connect', paramsJson);
            }
      }

    /************************************* ROOM *************************************** */
      join_room() {
            let paramsArr = {};
            paramsArr['room_key'] = this.roomKey.getValue();
            let paramsJson = JSON.stringify( paramsArr );
            this.socket.emit( 'join_room', paramsJson );
      }

      join_add_texts( textsArr: {} ) {
            if ( textsArr != null ) {
                  let textsArr1 = [];
                  for ( let key in textsArr ) {
                        let txt = new Txt( textsArr[key]['user_name'], textsArr[key]['message'], textsArr[key]['message_type'], textsArr[key]['font'], textsArr[key]['color'] );
                        textsArr1.push( txt );
                  }
                  this.textsArr.next( textsArr1 );
            }
      }

      leave_room( roomKey: string ) {
            let paramsArr = {};
            paramsArr['room_key'] = roomKey;

            let paramsJson = JSON.stringify( paramsArr );
            this.socket.emit( 'leave_room', paramsJson );
            this.textsArr.next( [] );
      }

    update_moderator( userName: string, roomKey: string ) {
        let paramsArr = {};
        paramsArr['moderator'] = userName;
        paramsArr['room_key'] = roomKey;

        let paramsJson = JSON.stringify( paramsArr );
        this.socket.emit( 'update_moderator', paramsJson );
    }

    change_my_room_prices( prices = [], roomKey: string ) {
        let paramsArr = {};
        paramsArr['prices'] = prices;
        paramsArr['room_key'] = roomKey;
        let paramsJson = JSON.stringify( paramsArr );
        this.socket.emit( 'update_room_prices', paramsJson );
    }

      ban( userName: string, roomKey: string ) {
            let paramsArr = {};
            paramsArr['user_name'] = userName;
            paramsArr['room_key'] = roomKey;

            let paramsJson = JSON.stringify( paramsArr );
            this.socket.emit( 'ban', paramsJson );
      }

      set_friends( friendsObj: {} ) {
            this.friendsArr = Object.keys( friendsObj ).map( function ( personNamedIndex ) {
                  let person = friendsObj[personNamedIndex];
                  return person;
            } );
      }

      add_friend( userName: string, userType: number ) {
            let currentUsersList = this.usersList.value;
            for ( let key in currentUsersList ) {
                  if ( currentUsersList[key]['user_name'] == userName ) {
                  currentUsersList[key]['friend'] = 1;
                  this.friendsArr.push( { user_name: userName, user_type: userType } );
                  }
            }
            this.usersList.next( currentUsersList );
      }

      remove_friend( userName: string ) {
            let currentUsersList = this.usersList.value;
            for ( let key in currentUsersList ) {
                  if ( currentUsersList[key]['user_name'] == userName ) {
                  currentUsersList[key]['friend'] = 0;
                  for ( let key1 in this.friendsArr ) {
                        if ( this.friendsArr[key1]['user_name'] == userName ) {
                              delete this.friendsArr[key1];
                        }
                  }
                  }
            }
            this.usersList.next( currentUsersList );
      }

      mute( userName: string, roomKey: string ) {
            let paramsArr = {};
            paramsArr['room_key'] = roomKey;
            paramsArr['user_name'] = userName;

            this.socket.emit( 'mute', paramsArr);
      }

      unmute( userName: string, roomKey: string ) {
            let paramsArr = {};
            paramsArr['room_key'] = roomKey;
            paramsArr['user_name'] = userName;

            this.socket.emit( 'unmute', paramsArr);
      }

      set_goal( roomKey: string, goalText: string, amount: number ) {
            let paramsArr = {};
            paramsArr['room_key'] = roomKey;
            paramsArr['goal_text'] = goalText;
            paramsArr['amount'] = amount;

            let paramsJson = JSON.stringify( paramsArr );
            this.socket.emit( 'set_goal', paramsJson );
      }

      refresh_my_tokens() {
            this.socket.emit( 'refresh_my_tokens', {} );
      }

      make_moderator(userName: string, roomKey: string) {
            let paramsArr = {};
            paramsArr['room_key'] = roomKey;
            paramsArr['user_name'] = userName;

            this.socket.emit( 'make_moderator', paramsArr);
      }

    remove_moderator(userName: string, roomKey: string){
        let paramsArr = {};
        paramsArr['room_key'] = roomKey;
        paramsArr['user_name'] = userName;
        this.socket.emit( 'remove_moderator', paramsArr);
    }

      /******************************* PRIVATES ********************************* */
      private_request( roomKey: string, modelName: string, privateType: number ) {
            let paramsArr = {};
            paramsArr['room_key'] = roomKey;
            paramsArr['model_name'] = modelName;
            paramsArr['private_type'] = privateType;

            let paramsJson = JSON.stringify( paramsArr );

            this.socket.emit( 'private_request', paramsJson );
      }

      accept_private( roomType: string, userName: string = '', roomKey: string, privateRoomKey: string ) {
            if ( this.userType == 2 ) {
                  let paramsArr = {};
                  paramsArr['room_key'] = roomKey;
                  paramsArr['private_room_key'] = privateRoomKey;
                  paramsArr['room_type'] = roomType;
                  paramsArr['user_name'] = userName;

                  let paramsJson = JSON.stringify( paramsArr );
                  this.socket.emit( 'accept_private', paramsJson );
            }
      }

      reject_private( userName: string, roomKey: string ) {
            if ( this.userType == 2 ) {
                  let paramsArr = {};
                  paramsArr['room_key'] = roomKey;
                  paramsArr['user_name'] = userName;
                  let paramsJson = JSON.stringify( paramsArr );
                  this.socket.emit( 'reject_private', paramsJson );

                  let currentTextsArr = this.textsArr.value;
                  let newTextsArr = [];


                  if ( currentTextsArr.length > 0 ) {
                  for ( let key in currentTextsArr ) {
                        if ( userName == currentTextsArr[key].userName && ( currentTextsArr[key].messageType == 'private_request' || currentTextsArr[key].messageType == 'true_private_request' ) ) {
                              let message = 'You rejected the private request';
                              let txt = new Txt( userName, message, 'private_rejected', '', this.myUserName );
                              newTextsArr.push( txt );
                        } else {
                              newTextsArr.push( currentTextsArr[key] );
                        }
                  }
                  this.textsArr.next( newTextsArr );
                  }
            }
      }

      leave_private( privateRoomKey: string = '' ) {
            let paramsArr = {};
            if ( !privateRoomKey ) {
                  privateRoomKey = this.privateRoomKey.getValue();
            }

            if ( privateRoomKey ) {
                  paramsArr['private_room_key'] = privateRoomKey;
                  let paramsJson = JSON.stringify( paramsArr );
                  this.socket.emit( 'leave_private', paramsJson );
            }
      }

      stop_private() {
            let paramsArr = {};
            paramsArr['private_room_key'] = this.privateRoomKey.getValue();
            let paramsJson = JSON.stringify( paramsArr );
            this.socket.emit( 'stop_private', paramsJson );
      }

      admin_private() {
            let paramsArr = {};
            paramsArr['private_room_key'] = this.privateRoomKey.getValue();
            paramsArr['room_key'] = this.roomKey.getValue();
            let paramsJson = JSON.stringify( paramsArr );
            this.socket.emit( 'admin_private', paramsJson );
      }

      join_private() {
            let paramsArr = {};
            paramsArr['private_room_key'] = this.privateRoomKey.getValue();
            paramsArr['room_key'] = this.roomKey.getValue();
            paramsArr['in_private'] = this.inPrivate.getValue();

            let paramsJson = JSON.stringify( paramsArr );
            this.socket.emit( 'join_private_room', paramsJson );
      }

    /************************************* TEXTS *************************************** */
      send_message( roomKey: string, message: string, messageType: string ) {
            let paramsArr = {};
            let userName = '';
            if ( this.userType == 1 ) {
                  paramsArr['font'] = this.usersService.userArr.font || 'font_prime';
                  paramsArr['color'] = this.usersService.userArr.level;
                  userName = this.myUserName;
            } else {
                  paramsArr['font'] = this.usersService.userArr.font || 'font_prime';
                  paramsArr['color'] = 100;
                  userName = this.myUserName;
            }

            paramsArr['message'] = message;
            paramsArr['message_type'] = messageType;
            paramsArr['room_key'] = roomKey;

            let paramsJson = JSON.stringify( paramsArr );

            if ( messageType == 'text' ) {
                  let txt = new Txt( userName, message, messageType, paramsArr['font'], paramsArr['color'] );
                  if ( this.inPrivate.getValue() != 0 ) {
                  let currentValue = this.privateTextsArr.value;
                  let updatedValue = [...currentValue, txt];
                  this.privateTextsArr.next( updatedValue );
                  } else {
                  let currentValue = this.textsArr.value;
                  let updatedValue = [...currentValue, txt];
                  this.textsArr.next( updatedValue );
                  }
            }
            this.socket.emit( 'send_message', paramsJson );
      }

      add_message( txt: Txt ) {
            if ( this.inPrivate.getValue() != 0 ) {
                  let currentValue = this.privateTextsArr.value;
                  let updatedValue = [...currentValue, txt];
                  this.privateTextsArr.next( updatedValue );
            } else {
                  let currentValue = this.textsArr.value;
                  let updatedValue = [...currentValue, txt];
                  this.textsArr.next( updatedValue );
            }
      }

    /************************ TIPS COINS ***********************/
      send_tip( userName: string, roomKey: string, amount: number, tipMessage: string ) {
            let paramsArr = {};
            paramsArr['user_name'] = userName;
            paramsArr['amount'] = amount;
            paramsArr['room_key'] = roomKey;
            paramsArr['tip_message'] = tipMessage;

            let paramsJson = JSON.stringify( paramsArr );
            this.socket.emit( 'send_tip', paramsJson );
      }

    // receive_tip(result: string) {
    //     const receiveArr = JSON.parse( result );

    //     this.vibrate_lush(receiveArr['amount']);

    //     let message = receiveArr['user_name'] + ' sent ' + receiveArr['amount'] + ' <img src="assets/images/g3_white_small.png">';
    //     if ( receiveArr['tip_message'] != '' && this.userType == 2 ) {
    //         if ( receiveArr['streamer_name'] == this.myUserName ) {
    //             message += '<br /> Personal Message: ' + receiveArr['tip_message'];
    //         }
    //     }
    //     let txt = new Txt( '', message, 'tip', '', '' );
    //     this.add_message( txt );
    //     new Audio( 'assets/tips.mp3' ).play();
    // }

    /************************ BROADCAST ***********************/
      broadcast( start: boolean ) {
            if ( start == true ) this.socket.emit( 'start_broadcast', {} );
            if ( start == false ) this.socket.emit( 'stop_broadcast', {} );
      }


    /***************** LOGOUT ************** */
}
