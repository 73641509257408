import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs/internal/BehaviorSubject';

@Injectable({
    providedIn: 'root',
})
export class MailService {
    mailsArr = new BehaviorSubject<Array<any>>([]);
    castMailsArr = this.mailsArr.asObservable();

    constructor() {}
}
