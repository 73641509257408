import { Injectable } from '@angular/core';
import { ToastController, ToastOptions } from '@ionic/angular';

@Injectable({
  providedIn: 'root'
})
export class ToastService {

  constructor(private toastController: ToastController) { }

  create(options?: ToastOptions) {
    const defaultOptions: ToastOptions = {
      cssClass: '',
      duration: 5000
    };

    const defaultCloseBtn = {
      text: 'Close',
      role: 'cancel',
    };

    const closeBtn = options.buttons?.find(btn => typeof btn !== 'string' && btn.role === 'cancel');
    options = options ? { ...options, ...defaultOptions } : defaultOptions;
    if(!closeBtn) {
      // eslint-disable-next-line @typescript-eslint/dot-notation
      options['buttons'] = options.buttons ? [ ...options.buttons, defaultCloseBtn ] : [ defaultCloseBtn ];
    }
    return this.toastController.create(options);
  }
}
