/* eslint-disable @typescript-eslint/member-ordering */
/* eslint-disable eqeqeq */
/* eslint-disable max-len */
/* eslint-disable @typescript-eslint/quotes */
/* eslint-disable @typescript-eslint/naming-convention */
/* eslint-disable @typescript-eslint/dot-notation */
import { Component, ElementRef, Input, OnInit, ViewChild } from '@angular/core';
import { RequestService } from '../_services/request.service';
import { UserService } from '../_services/user.service';
import { User } from '../_models/user.model';
import { PaymentTokenData } from '../_types/ccid.type';
import { ActivatedRoute } from '@angular/router';
import { AlertController, ModalController, NavController, ToastController } from '@ionic/angular';
import { HttpClient } from '@angular/common/http';
import { MonoTypeOperatorFunction, Subject, firstValueFrom, last, retry, scan, share, switchMap, switchMapTo, takeUntil, takeWhile, tap, timer } from 'rxjs';
import { CurrencyPipe } from '@angular/common';
import { ToastService } from '../_services/toast.service';

declare const ccbill;

@Component({
  selector: 'app-card-input',
  templateUrl: './card-input.page.html',
  styleUrls: ['./card-input.page.scss'],
  providers: [CurrencyPipe]
})
export class CardInputPage implements OnInit {

  @Input() isModal = false;

  firstName = '';
  lastName = '';
  cardName: string;

  loadingButtons: boolean;
  oauth_token: string;
  userData: User;
  paymentTokenData: PaymentTokenData;
  tokenCost: number;
  customCreditCost = 0;
  customCreditValue = 0;
  selectedExpMonth = 1;
  countries = [
    {
      name: 'United States',
      Iso2: 'US'
    },
    {
      name: 'Canada',
      Iso2: 'CA'
    }, {
      name: 'United Kingdom',
      Iso2: 'GB'
    },
  ];
  selected_country = 'US';
  selected_state;
  states = [];
  cardStatusChecked = false;
  userHasCard: boolean;
  months: { index: string; name: string }[] = [];
  years = [];
  cards: any[];
  pollCount = 0;
  stopPolling = new Subject();;

  constructor(
    private currencyPipe: CurrencyPipe,
    public toastController: ToastService,
    private alertController: AlertController,
    private navCtrl: NavController,
    private modalCtrl: ModalController,
    private route: ActivatedRoute,
    private usersService: UserService,
    private requestService: RequestService,
    private http: HttpClient) { }

  ngOnInit() {
    this.userData = this.usersService.userArr;
    this.does_user_have_card();
    this.get_access_token();
    this.customCreditCost = Number(this.route.snapshot.paramMap.get('price'));
    this.calculate_custom_credit();
    this.get_countries();
    this.get_country_states();
    this.populateMonthDropdown();
    this.populateYearDropdown();
    this.get_user_cards();
  }

  pay_now() {
    if (this.userHasCard) {
      this.choose_custom_package();
      return;
    }
    this.createPaymentToken();
  }

  setExpMonth(evt) {
    this.selectedExpMonth = Number(evt.target.value);
  }

  get_access_token() {
    this.loadingButtons = true;
    this.requestService.payment('generate_oauth_token').subscribe((resultJson) => {
      this.loadingButtons = false;
      if (resultJson['success'] == true) {
        this.oauth_token = resultJson?.['result']?.access_token;
      }
    });
  }

  createPaymentToken() {
    const widget = new ccbill.CCBillAdvancedWidget('9EXsdUcLseVEW82UwX5b39dzlYfr0Bl');
    try {
      const result = widget.createPaymentToken(this.oauth_token, '952809', '0000', false, false);
      result.then(
        (data) => {
          console.log('SUCCESS', data);
          this.loadingButtons = false;
          return data.json();
        },
        async (error) => {
          console.log('ERROR');
          this.loadingButtons = false;
          throw await error.json();
        }).then((json: PaymentTokenData) => {
          this.paymentTokenData = json;
          this.completeSCARequirement(this.paymentTokenData.paymentTokenId);
        }).catch((error) => {
          this.loadingButtons = false;
          this.get_access_token();
          this.showStatusAlert('Error', 'An error occured while charging your card');
        });
    } catch (error) {
      this.loadingButtons = false;
      const errors = [];
      // eslint-disable-next-line prefer-arrow/prefer-arrow-functions
      let parsedErrorMessageList = '';
      const userReadableKeys = {
        zipcode: 'Postal Code',
        expMonth: 'Expiry Month',
        expYear: 'Expiry Year',
        cardNum: 'Card Number',
        customerFname: 'First Name',
        customerLname: 'Last Name',
        country: 'Country',
        state: 'State',
        city: 'City',
        address1: 'Address'
      };
      error.forEach((item: { message: string; propertyName: string }) => {
        if (item.propertyName === 'paymentInfo' || item.propertyName === 'customerInfo') {
          return;
        }
        const msg = item.message.split('.');
        const parsedMessage = msg[1].replace(item.propertyName, userReadableKeys[item.propertyName]);
        errors.push(parsedMessage);
        parsedErrorMessageList += `<b class="font_hemy">${parsedMessage}</b><br />`;
      });
      this.showStatusAlert('Error', parsedErrorMessageList);
    }
  }

  setCardName(fNameEvt, lNameEvt) {
    if (fNameEvt) {
      this.firstName = fNameEvt.target.value;
    }
    if (lNameEvt) {
      this.lastName = lNameEvt.target.value;
    }
    this.cardName = `${this.firstName} ${this.lastName}`;
  }

  async choose_custom_package() {
    if (!this.userHasCard) {
      return;
    }
    this.loadingButtons = true;
    this.requestService.payment('charge_last_token', {
      initialPrice: this.customCreditCost,
      currencyCode: 'US',
    }).subscribe(res => {
      if (res['payment_completed']) {
        this.showToastMessage(`Payment of ${this.currencyPipe.transform(this.customCreditCost)} charged successfully!`);
        // this.navCtrl.back();
        console.log(res);
        this.poll_charge_success(res['transaction_id']);
      } else {
        if (res['success']) {
          this.loadingButtons = false;
          this.get_access_token();
          this.showStatusAlert('Error', 'We were unable to charge your card');
        } else {
          this.loadingButtons = false;
          this.get_access_token();
          this.showStatusAlert('Error', 'An error occured while charging your card');
        }
      }
    }, () => {
      this.loadingButtons = false;
      this.get_access_token();
      this.showStatusAlert('Error', 'An error occured while charging your card');
    });

  }

  onCreditFieldFocus(editedField: 'cost' | 'value' = 'cost') {
    if (editedField === 'cost') {
      if (this.customCreditCost <= 0) {
        this.customCreditCost = null;
      }
    } else {
      if (this.customCreditValue <= 0) {
        this.customCreditValue = null;
      }
    }
  }

  onCreditFieldBlur(editedField: 'cost' | 'value' = 'cost') {
    if (editedField === 'cost') {
      if (this.customCreditCost <= 0) {
        this.customCreditCost = 0;
      }
    } else {
      if (this.customCreditValue <= 0) {
        this.customCreditValue = 0;
      }
    }
  }

  calculate_custom_credit(editedField: 'cost' | 'value' = 'cost') {
    this.customCreditCost = Number(this.customCreditCost) || 0;
    this.customCreditValue = Number(this.customCreditValue) || 0;

    setTimeout(() => {
      if (editedField === 'cost') {
        if (this.customCreditCost <= 0) {
          this.customCreditValue = this.customCreditCost = 0;
        } else {
          this.customCreditValue = this.customCreditCost * 10;
        }
      }
      else {
        if (this.customCreditValue <= 0) {
          this.customCreditCost = this.customCreditValue = 0;
        } else {
          this.customCreditCost = Math.round(((this.customCreditValue / 10) + Number.EPSILON) * 100) / 100;
        }
      }

      console.log(this.customCreditValue, this.customCreditCost);
    }, 500);

  }

  private async does_user_have_card() {
    this.cardStatusChecked = this.userHasCard = false;
    try {
      const cardCheckResult = await firstValueFrom(this.requestService.payment('check_payment_token_exists'));
      this.cardStatusChecked = true;
      this.userHasCard = !!cardCheckResult['has_token'];
      return this.userHasCard;
    } catch (e) {
      this.cardStatusChecked = true;
      console.log(e);
      return false;
    }
  }

  showCardForm() {
    this.userHasCard = false;
  }

  async completeSCARequirement(payment_token_id: PaymentTokenData['paymentTokenId']) {
    const widget = new ccbill.CCBillAdvancedWidget('9EXsdUcLseVEW82UwX5b39dzlYfr0Bl');
    const result = widget.isScaRequiredForPaymentToken(this.oauth_token, payment_token_id);
    console.log(result);
    try {
      const scaRequired = await this.getWidgetResultData(result);
      console.log(scaRequired);
      if (scaRequired) { // Check if 3DS is required
        const customerAuthResult = widget.authenticateCustomer(this.oauth_token, document.forms.namedItem('cardForm'));
        try {
          const scaCustomerAuthResult = await this.getWidgetResultData(customerAuthResult);
          console.log(scaCustomerAuthResult);
          if (!scaCustomerAuthResult) {
            this.get_access_token();
            this.showStatusAlert('Error', 'Authentication failed');
          } else {
            // call 3ds token charge endpoint here
            this.requestService.payment('charge_payment_token_3ds', {
              payment_token: payment_token_id,
              initialPrice: this.customCreditCost,
              currencyCode: (document.forms.namedItem('cardForm').querySelector('#_ccbillId_currencyCode') as any).value
            } as any).subscribe((resultJson) => {
              this.loadingButtons = false;

              if (resultJson['success'] === true) {
                if (resultJson['payment_completed']) {
                  this.showToastMessage(`Payment of ${this.currencyPipe.transform(this.customCreditCost)} charged successfully!`);
                  // this.navCtrl.back();
                  this.poll_charge_success(resultJson['transaction_id']);
                }
                this.get_access_token();
              }
            });
          }
        } catch (e) {
          this.get_access_token();
          console.log(e);
        }
      } else {
        // call non 3ds token charge endpoint here
        this.requestService.payment('charge_payment_token', {
          payment_token: payment_token_id,
          initialPrice: this.customCreditCost,
          currencyCode: (document.forms.namedItem('cardForm').querySelector('#_ccbillId_currencyCode') as any).value
        } as any).subscribe((resultJson) => {
          this.loadingButtons = false;
          if (resultJson['success'] === true) {
            if (resultJson['payment_completed']) {
              this.showToastMessage(`Payment of ${this.currencyPipe.transform(this.customCreditCost)} charged successfully!`);
              // this.navCtrl.navigateBack('dashboard');
              console.log(resultJson);
              this.poll_charge_success(resultJson['transaction_id']);
            }
            this.get_access_token();
          }
        });
      }
    } catch (e) {
      console.log(e);
      this.get_access_token();
    }
  }

  poll_charge_success(transaction_id) {
    const payload = [];
    let transactionChargedSuccessfully = false;
    payload['transaction_id'] = transaction_id;
    const chargeSuccessStatus$ = timer(1, 5000).pipe(
      switchMap(() => {
        this.pollCount += 1;
        if (this.pollCount > 5) {
          this.showToastMessage(`Kindly refresh to get your latest balance`);
          this.stopPolling.next(null);
          if (this.isModal) {
            this.modalCtrl.dismiss();
          } else {
            this.navCtrl.pop();
          }
          return;
        }
        const statusObserver = this.requestService.payment('verify_transaction', payload);
        statusObserver.subscribe(res => {
          console.log(res);
          transactionChargedSuccessfully = res['transaction_seen'];
          if (transactionChargedSuccessfully) {
            this.showToastMessage(`Payment of ${this.currencyPipe.transform(this.customCreditCost)} completed successfully!`);
            this.stopPolling.next(null);
            if (this.isModal) {
              this.modalCtrl.dismiss();
            } else {
              this.navCtrl.pop();
            }
            return;
          }
          if (transactionChargedSuccessfully || this.pollCount >= 5) {
            if (this.isModal) {
              this.modalCtrl.dismiss();
            } else {
              this.navCtrl.pop();
            }
          }
          // **

        });
        return statusObserver;
      }),
      retry(),
      share(),
      takeUntil(this.stopPolling));

    chargeSuccessStatus$.subscribe();
    return;
  }

  // attemptsGuardFactory(maxAttempts: number) {
  //   return (attemptsCount: number) => {
  //     if (attemptsCount > maxAttempts) {
  //       throw new Error("Exceeded maxAttempts");
  //     }
  //   };
  // }

  // pollWhile<T>(
  //   pollInterval: number,
  //   isPollingActive: (res: T) => boolean,
  //   maxAttempts = 5,
  //   emitOnlyLast = false
  // ): MonoTypeOperatorFunction<T> {
  //   return source$ => {
  //     const poll$ = timer(0, pollInterval).pipe(
  //       scan(attempts => ++attempts, 0),
  //       tap(this.attemptsGuardFactory(maxAttempts)),
  //       switchMapTo(source$),
  //       takeWhile(isPollingActive, true)
  //     );

  //     return emitOnlyLast ? poll$.pipe(last()) : poll$;
  //   };
  // }

  async delete_card(cardId) {
    const payload = [];
    payload['card_id'] = cardId;
    this.requestService.payment('delete_card', payload).subscribe(async (resultJson) => {
      const toast = await this.toastController.create({
        message: 'Card deleted successfully'
      });
      this.get_user_cards();
      toast.present();
    });
  }

  async getWidgetResultData<T = any>(result: Promise<Response>) {
    return new Promise((res, rej) => {
      try {
        result.then(
          (data) => {
            console.log('SUCCESS', data);
            return data.json();
          }, (error) => {
            console.log('ERROR');
            return error;
          }).then((json: T) => {
            console.log('RESULT :[' + JSON.stringify(json) + ']');
            res(json);
            // this.paymentTokenData = json;
          }).catch((error) => {
            rej(error);
          });
        console.log(`FINISHED`);
      } catch (error) {
        const errors = [];
        // eslint-disable-next-line prefer-arrow/prefer-arrow-functions
        error.forEach((item: { message: string }) => {
          const msg = item.message.split('.');
          errors.push(msg[1]);
        });
        console.error(`ERROR ` + JSON.stringify(errors));
        // alert('ERROR: Unable to generate Payment Token: ' + JSON.stringify(errors));
        rej(errors);
      }
    });
  }

  get_countries() {
    this.http.get('https://countriesnow.space/api/v0.1/countries/iso')
      .subscribe((res: any) => {
        console.log(res.data);
        this.countries.push(...res.data);
      });
  }

  get_country_states() {
    const country = this.countries.find(countryData => countryData.Iso2 === this.selected_country);
    this.http.post('https://countriesnow.space/api/v0.1/countries/states', { country: country?.name })
      .subscribe((res: any) => {
        console.log(res.data);
        this.states = res.data.states;
      });
  }

  populateMonthDropdown() {
    this.months = [
      { index: "01", name: "January" },
      { index: "02", name: "February" },
      { index: "03", name: "March" },
      { index: "04", name: "April" },
      { index: "05", name: "May" },
      { index: "06", name: "June" },
      { index: "07", name: "July" },
      { index: "08", name: "August" },
      { index: "09", name: "September" },
      { index: "10", name: "October" },
      { index: "11", name: "November" },
      { index: "12", name: "December" },
    ];
  }

  populateYearDropdown() {
    const currentYear = new Date().getFullYear();
    const future = currentYear + 30;
    for (let i = 0; i <= future; i++) {
      this.years.push(currentYear + i);
    }
  }

  async showStatusAlert(header: string, message: string, buttons = ['Ok']) {
    const alert = await this.alertController.create({
      header,
      message,
      buttons,
      cssClass: 'alert-class'
    });

    await alert.present();
  }

  async showToastMessage(message: string) {
    const toast = await this.toastController.create({
      message,
      duration: 5000,
      buttons: [
        {
          text: 'Close',
          role: 'cancel',
        }],
    });

    await toast.present();
  }

  async get_user_cards() {
    this.requestService.payment('get_user_cards').subscribe((resultJson) => {
      this.loadingButtons = false;
      if (resultJson['success'] == true) {
        delete resultJson['success'];
        const cards: any[] = Object.values(resultJson);
        for (const card of cards) {
          card.card_data = JSON.parse(card.card_data);
        };
        this.cards = cards.filter(card => !!card.card_data);
      }
    });
  }

  set_default_card(cardId) {
    this.requestService.payment('set_default_card', {
      card_id: cardId
    }).subscribe(async (resultJson) => {
      this.loadingButtons = false;
      if (resultJson['success'] == true) {
        await this.get_user_cards();
        this.showToastMessage('Default card updated successfully');
      } else {
        this.showToastMessage('Default card update');
      }
    });
  }

  ionViewDidLeave() {
    this.stopPolling.next(null);
    this.requestService.users('get_user_tokens', []).subscribe((resultJson) => {
      if (resultJson['success'] == true) {
        console.log('Got user tokens', resultJson);
        this.usersService.update_tokens(Number(resultJson['tokens']));
      }
    });
  }

}
