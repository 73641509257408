import { Component, Input, OnInit } from '@angular/core';
import { ModalController } from '@ionic/angular';
import { RequestService } from '../../_services/request.service';
import { UserService } from '../../_services/user.service';

@Component({
  selector: 'app-mail-new-modal',
  templateUrl: './mail-new-modal.page.html',
  styleUrls: ['./mail-new-modal.page.scss'],
})
export class MailNewModalPage implements OnInit {
  message: string = '';
  sending: boolean = false;
  @Input() userName: string;

  constructor(
    private modalCtrl: ModalController,
    private request: RequestService
  ) { }

  ngOnInit() {
  }

  send_message() {        
    this.sending = true;

    let testArr: any = [];            
    testArr['message']  = this.message;   
    testArr['user_name']= this.userName;       
    this.request.mail('send_profile_message', testArr).subscribe(resultArr => {      
      this.sending = false;
      this.dismiss();
    });
  }

  dismiss() {
    this.modalCtrl.dismiss({
      'dismissed': true
    });
  }

}
