export const environment = {
    production: true,
    websiteUrl: 'https://girlsgonegame.com/',
    nodeUrl: 'https://girlsgonegame.com:1338',
    apiUrl: 'https://girlsgonegame.com/api/',
    publicUrl: 'https://girlsgonegame.com/api/public/',
    oneSignalAppId: 'd4a755b0-ab25-476f-a91f-ec141482742e',
    safariWebId: 'web.onesignal.auto.00b75e31-4d41-4106-ab79-a5c68121f393',
    autostartVideo: true,
};
