import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { RouteReuseStrategy } from '@angular/router';

import { IonicModule, IonicRouteStrategy } from '@ionic/angular';
import { SocketIoModule, SocketIoConfig } from 'ngx-socket-io';

import { AppComponent } from './app.component';
import { AppRoutingModule } from './app-routing.module';
import { environment } from 'src/environments/environment';

import { HttpClientModule } from '@angular/common/http';


import { FormsModule } from '@angular/forms';
import { MailNewModalPage } from './messaging/mail-new-modal/mail-new-modal.page';
import { RecordingModalPage } from './recording-modal/recording-modal.page';

const config: SocketIoConfig = { url: environment.nodeUrl, options: {} };

import { VgCoreModule } from '@videogular/ngx-videogular/core';
import { VgControlsModule } from '@videogular/ngx-videogular/controls';
import { VgOverlayPlayModule } from '@videogular/ngx-videogular/overlay-play';
import { VgBufferingModule } from '@videogular/ngx-videogular/buffering';
import { VgStreamingModule } from '@videogular/ngx-videogular/streaming';

import { AgeVerificationModalPage } from './_components/age-verification-modal/age-verification-modal.page';
import { ChatTipModalPage } from './chat/chat-tip-modal/chat-tip-modal.page';
import { CardInputPage } from './card-input/card-input.page';
import { SharedModule } from './_components/shared/shared.module';


@NgModule({
  declarations: [AppComponent, ChatTipModalPage, MailNewModalPage, RecordingModalPage, AgeVerificationModalPage, CardInputPage],
  entryComponents: [ChatTipModalPage, MailNewModalPage, RecordingModalPage, AgeVerificationModalPage, CardInputPage],
  imports: [
    FormsModule,
    VgCoreModule,
    VgControlsModule,
    VgOverlayPlayModule,
    VgBufferingModule,
    VgStreamingModule,
    BrowserModule,
    IonicModule.forRoot(),
    AppRoutingModule,
    HttpClientModule,
    SocketIoModule.forRoot(config),
    SharedModule
  ],
  providers: [{ provide: RouteReuseStrategy, useClass: IonicRouteStrategy }],
  bootstrap: [AppComponent],
})
export class AppModule {}
