import { NgModule } from '@angular/core';
import { PreloadAllModules, RouterModule, Routes } from '@angular/router';
import { AuthGuard } from './_services/auth.guard';
import { GuestGuard } from './_services/guest.guard';
import { SguardGuard } from './_services/sguard.guard';
import { MguardGuard } from './_services/mguard.guard';

const routes: Routes = [
      {
            path: '',
            redirectTo: '/',
            pathMatch: 'full',
      },
      {
            path: '',
            loadChildren: () => import( './home/home.module' ).then( ( m ) => m.HomePageModule ),
      },
      {
            path: 'home',
            loadChildren: () => import( './home/home.module' ).then( ( m ) => m.HomePageModule ),
      },
      {
            path: 'signup-streamer',
            loadChildren: () => import( './signup_streamers/signup-streamer/signup-streamer.module' ).then( ( m ) => m.SignupStreamerPageModule ),
            canLoad: [GuestGuard],
      },
      {
            path: 'login/admin_login/:userKey',
            loadChildren: () => import( './login/login/login.module' ).then( ( m ) => m.LoginPageModule ),
      },
      {
            path: 'login/:success',
            loadChildren: () => import( './login/login/login.module' ).then( ( m ) => m.LoginPageModule ),
      },
      {
        path: 'card-input',
        loadChildren: () => import('./card-input/card-input.module').then( m => m.CardInputPageModule),
        canLoad: [AuthGuard, MguardGuard],
      },
      {
            path: 'login',
            loadChildren: () => import( './login/login/login.module' ).then( ( m ) => m.LoginPageModule ),
            canLoad: [GuestGuard],
      },
      {
            path: 'forgot',
            loadChildren: () => import( './login/forgot/forgot.module' ).then( ( m ) => m.ForgotPageModule ),
            canLoad: [GuestGuard],
      },
      {
            path: 'email-verification/:userKey',
            loadChildren: () => import( './streamer-unverified/email-verification/email-verification.module' ).then( ( m ) => m.EmailVerificationPageModule ),
      },
      {
            path: 'email-verification',
            loadChildren: () => import( './streamer-unverified/email-verification/email-verification.module' ).then( ( m ) => m.EmailVerificationPageModule ),
      },
      {
            path: 'dashboard',
            loadChildren: () => import( './dashboard-streamer/dashboard/dashboard.module' ).then( ( m ) => m.DashboardPageModule ),
            canLoad: [AuthGuard],
      },
      {
            path: 'streamer-documents',
            loadChildren: () => import( './streamer-unverified/streamer-documents/streamer-documents.module' ).then( ( m ) => m.StreamerDocumentsPageModule ),
            canLoad: [SguardGuard, AuthGuard],
      },
      {
            path: 'streamer-avatar',
            loadChildren: () => import( './dashboard-streamer/profile/streamer-avatar/streamer-avatar.module' ).then( ( m ) => m.StreamerAvatarPageModule ),
            canLoad: [SguardGuard, AuthGuard],
      },
      {
            path: 'streamer-profile',
            loadChildren: () => import( './dashboard-streamer/profile/streamer-profile/streamer-profile.module' ).then( ( m ) => m.StreamerProfilePageModule ),
            canLoad: [SguardGuard, AuthGuard],
      },
      {
            path: 'streamer-payment',
            loadChildren: () => import( './dashboard-streamer/payment/streamer-payment/streamer-payment.module' ).then( ( m ) => m.StreamerPaymentPageModule ),
            canLoad: [AuthGuard],
      },
      {
            path: 'terms/:pageName',
            loadChildren: () => import( './terms/terms.module' ).then( ( m ) => m.TermsPageModule ),
      },
      {
            path: 'terms',
            loadChildren: () => import( './terms/terms.module' ).then( ( m ) => m.TermsPageModule ),
      },

      {
            path: 'streamer-settings',
            loadChildren: () => import( './dashboard-streamer/settings/streamer-settings/streamer-settings.module' ).then( ( m ) => m.StreamerSettingsPageModule ),
            canLoad: [SguardGuard, AuthGuard],
      },
      {
            path: 'streamer-account',
            loadChildren: () => import( './dashboard-streamer/settings/streamer-account/streamer-account.module' ).then( ( m ) => m.StreamerAccountPageModule ),
            canLoad: [SguardGuard, AuthGuard],
      },
      {
            path: 'signup-step1',
            loadChildren: () => import( './signup-users/signup-step1/signup-step1.module' ).then( ( m ) => m.SignupStep1PageModule ),
            canLoad: [GuestGuard],
      },
      {
            path: 'signup-step2',
            loadChildren: () => import( './signup-users/signup-step2/signup-step2.module' ).then( ( m ) => m.SignupStep2PageModule ),
            canLoad: [GuestGuard],
      },
      {
            path: 'signup-step3',
            loadChildren: () => import( './signup-users/signup-step3/signup-step3.module' ).then( ( m ) => m.SignupStep3PageModule ),
            canLoad: [GuestGuard],
      },
      {
            path: 'user-settings',
            loadChildren: () => import( './user-settings/user-settings.module' ).then( ( m ) => m.UserSettingsPageModule ),
            canLoad: [AuthGuard],
      },
      {
            path: 'user-account',
            loadChildren: () => import( './user-account/user-account.module' ).then( ( m ) => m.UserAccountPageModule ),
            canLoad: [AuthGuard],
      },
      {
            path: 'streamer-faq',
            loadChildren: () => import( './dashboard-streamer/help/streamer-faq/streamer-faq.module' ).then( ( m ) => m.StreamerFaqPageModule ),
      },
      {
            path: 'user-faq',
            loadChildren: () => import( './user-faq/user-faq.module' ).then( ( m ) => m.UserFaqPageModule ),
      },
      {
            path: 'streamer-support',
            loadChildren: () => import( './dashboard-streamer/help/streamer-support/streamer-support.module' ).then( ( m ) => m.StreamerSupportPageModule ),
            canLoad: [SguardGuard, AuthGuard],
      },
      {
            path: 'buy',
            loadChildren: () => import( './buy/buy.module' ).then( ( m ) => m.BuyPageModule ),
            canLoad: [AuthGuard],
      },
      {
            path: 'streamer-gallery/:galleryId',
            loadChildren: () => import( './dashboard-streamer/media/streamer-gallery/streamer-gallery.module' ).then( ( m ) => m.StreamerGalleryPageModule ),
            canLoad: [SguardGuard, AuthGuard],
      },
      {
            path: 'streamer-galleries',
            loadChildren: () => import( './dashboard-streamer/media/streamer-galleries/streamer-galleries.module' ).then( ( m ) => m.StreamerGalleriesPageModule ),
            canLoad: [SguardGuard, AuthGuard],
      },
      {
            path: 'contact',
            loadChildren: () => import( './contact/contact.module' ).then( ( m ) => m.ContactPageModule ),
      },
      {
            path: 'chat/:userName',
            //     loadChildren: () => import( './chat/chat.module' ).then( ( m ) => m.ChatPageModule ),
            loadChildren: () => import( './chat/chat/chat.module' ).then( ( m ) => m.ChatPageModule ),
      },
      {
            path: 'chat',
            loadChildren: () => import( './chat/chat/chat.module' ).then( ( m ) => m.ChatPageModule ),
      },
      {
            path: 'profile/:userName',
            loadChildren: () => import( './profile/profile.module' ).then( ( m ) => m.ProfilePageModule ),
      },
      {
            path: 'profile',
            loadChildren: () => import( './profile/profile.module' ).then( ( m ) => m.ProfilePageModule ),
      },
      {
            path: 'mails',
            loadChildren: () => import( './messaging/mail/mail.module' ).then( ( m ) => m.MailPageModule ),
            canLoad: [AuthGuard],
      },
      {
            path: 'mail-read/:index',
            loadChildren: () => import( './messaging/mail-read/mail-read.module' ).then( ( m ) => m.MailReadPageModule ),
            canLoad: [AuthGuard],
      },
      {
            path: 'mail-read',
            loadChildren: () => import( './messaging/mail-read/mail-read.module' ).then( ( m ) => m.MailReadPageModule ),
            canLoad: [AuthGuard],
      },
      {
            path: 'friends-list',
            loadChildren: () => import( './dashboard-streamer/users/friends-list/friends-list.module' ).then( ( m ) => m.FriendsListPageModule ),
            canLoad: [AuthGuard],
      },
      {
            path: 'banned-list',
            loadChildren: () => import( './dashboard-streamer/users/banned-list/banned-list.module' ).then( ( m ) => m.BannedListPageModule ),
            canLoad: [AuthGuard],
      },
      {
            path: 'balance',
            loadChildren: () => import( './dashboard-streamer/payment/balance/balance.module' ).then( ( m ) => m.BalancePageModule ),
            canLoad: [SguardGuard, AuthGuard],
      },
      {
            path: 'pm/:userName',
            loadChildren: () => import( './messaging/pm/pm.module' ).then( ( m ) => m.PmPageModule ),
            canLoad: [AuthGuard],
      },
      {
            path: 'pm',
            loadChildren: () => import( './messaging/pm/pm.module' ).then( ( m ) => m.PmPageModule ),
            canLoad: [AuthGuard],
      },
      {
            path: 'pm-list',
            loadChildren: () => import( './pm-list/pm-list.module' ).then( ( m ) => m.PmListPageModule ),
            canLoad: [AuthGuard],
      },
      {
            path: 'pm-menu',
            loadChildren: () => import( './pm-menu/pm-menu.module' ).then( ( m ) => m.PmMenuPageModule ),
            canLoad: [AuthGuard],
      },
      {
            path: 'streamer-payment-history',
            loadChildren: () => import( './dashboard-streamer/payment/streamer-payment-history/streamer-payment-history.module' ).then( ( m ) => m.StreamerPaymentHistoryPageModule ),
            canLoad: [SguardGuard, AuthGuard],
      },
      {
            path: 'shut-down',
            loadChildren: () => import( './shut-down/shut-down.module' ).then( ( m ) => m.ShutDownPageModule ),
      },
      {
            path: 'obs-settings',
            loadChildren: () => import( './chat/obs-settings/obs-settings.module' ).then( ( m ) => m.ObsSettingsPageModule ),
            canLoad: [AuthGuard],
      },
      {
            path: 'payment/:type',
            loadChildren: () => import( './payment/payment.module' ).then( ( m ) => m.PaymentPageModule ),
            canLoad: [AuthGuard],
      },
      {
            path: 'payment/:type/:transactionId',
            loadChildren: () => import( './payment/payment.module' ).then( ( m ) => m.PaymentPageModule ),
            canLoad: [AuthGuard],
      },
      {
            path: 'notifications',
            loadChildren: () => import( './notifications/notifications.module' ).then( ( m ) => m.NotificationsPageModule ),
            canLoad: [AuthGuard],
      },
      {
            path: 'user-notifications/:activate',
            loadChildren: () => import( './dashboard-streamer/settings/user-notifications/user-notifications.module' ).then( ( m ) => m.UserNotificationsPageModule ),
            canLoad: [AuthGuard],
      },
      {
            path: 'user-notifications',
            loadChildren: () => import( './dashboard-streamer/settings/user-notifications/user-notifications.module' ).then( ( m ) => m.UserNotificationsPageModule ),
            canLoad: [AuthGuard],
      },
      {
            path: 'my-galleries',
            loadChildren: () => import( './my-galleries/my-galleries.module' ).then( ( m ) => m.MyGalleriesPageModule ),
            canLoad: [AuthGuard],
      },
      {
            path: 'gallery-slider-modal',
            loadChildren: () => import( './gallery-slider-modal/gallery-slider-modal.module' ).then( ( m ) => m.GallerySliderModalPageModule ),
      },
      {
            path: 'streamer-recordings',
            loadChildren: () => import( './dashboard-streamer/media/streamer-recordings/streamer-recordings.module' ).then( ( m ) => m.StreamerRecordingsPageModule ),
            canLoad: [SguardGuard, AuthGuard],
      },
      {
            path: 'my-recordings',
            loadChildren: () => import( './my-recordings/my-recordings.module' ).then( ( m ) => m.MyRecordingsPageModule ),
            canLoad: [AuthGuard],
      },
      {
            path: 'aff/:linkId',
            loadChildren: () => import( './aff/aff.module' ).then( m => m.AffPageModule )
      },
      {
            path: 'banned-countries',
            loadChildren: () => import( './dashboard-streamer/settings/banned-countries/banned-countries.module' ).then( m => m.BannedCountriesPageModule ),
            canLoad: [SguardGuard, AuthGuard],
      },
      {
            path: 'twitter-settings',
            loadChildren: () => import( './dashboard-streamer/media/twitter-settings/twitter-settings.module' ).then( m => m.TwitterSettingsPageModule ),
            canLoad: [SguardGuard, AuthGuard],
      },
      {
            path: 'streamer-tags',
            loadChildren: () => import('./dashboard-streamer/profile/streamer-tags/streamer-tags.module').then( m => m.StreamerTagsPageModule),
            canLoad: [AuthGuard],
      },
      {
            path: 'streamer-banner',
            loadChildren: () => import('./dashboard-streamer/profile/streamer-banner/streamer-banner.module').then( m => m.StreamerBannerPageModule),
            canLoad: [AuthGuard],
      },
      {
            path: 'user-profile',
            loadChildren: () => import('./user-profile/user-profile.module').then( m => m.UserProfilePageModule),
            canLoad: [AuthGuard],
      },
      {
            path: 'user-banner',
            loadChildren: () => import('./user-banner/user-banner.module').then( m => m.UserBannerPageModule),
            canLoad: [AuthGuard],
      },
      {
      path: 'user-avatar',
      loadChildren: () => import('./user-avatar/user-avatar.module').then( m => m.UserAvatarPageModule),
      canLoad: [AuthGuard],
      },
      {
      path: 'user/:userName',
      loadChildren: () => import('./user/user.module').then(m => m.UserPageModule)
      },

      {
            path: 'user',
            redirectTo: '/',
      },
      {
            path: 'chat-redirect',
            loadChildren: () => import('./chat/chat-redirect/chat-redirect.module').then( m => m.ChatRedirectPageModule),
            canLoad: [SguardGuard, AuthGuard],
      },
      {
      path: 'chat-emotes-modal',
            loadChildren: () => import('./chat/chat-emotes-modal/chat-emotes-modal.module').then( m => m.ChatEmotesModalPageModule)
      },
      {
            path: 'streamer-moderators',
            loadChildren: () => import('./dashboard-streamer/users/streamer-moderators/streamer-moderators.module').then( m => m.StreamerModeratorsPageModule),
            canLoad: [SguardGuard],
      },
      {
      path: 'chat-trans-modal',
            loadChildren: () => import('./chat/chat-trans-modal/chat-trans-modal.module').then( m => m.ChatTransModalPageModule)
      },
      {
            path: 'streamer-levels',
            loadChildren: () => import('./dashboard-streamer/payment/streamer-levels/streamer-levels.module').then( m => m.StreamerLevelsPageModule),
            canLoad: [SguardGuard],
      },
      {
            path: 'user-rewards',
            loadChildren: () => import('./dashboard-streamer/settings/user-rewards/user-rewards.module').then( m => m.UserRewardsPageModule),
            canLoad: [AuthGuard],
      },
      {
            path: 'signup-twitter/:requestType',
            loadChildren: () => import('./signup-twitter/signup-twitter.module').then( m => m.SignupTwitterPageModule)
      },

      {
            path: 'signup-twitter',
            loadChildren: () => import('./signup-twitter/signup-twitter.module').then( m => m.SignupTwitterPageModule)
      },
      {
            path: 'streamer-upgrade-level',
            loadChildren: () => import('./dashboard-streamer/payment/streamer-upgrade-level/streamer-upgrade-level.module').then( m => m.StreamerUpgradeLevelPageModule),
            canLoad: [SguardGuard],
      },
      {
            path: 'streamer-schedule',
            loadChildren: () => import('./dashboard-streamer/profile/streamer-schedule/streamer-schedule.module').then( m => m.StreamerSchedulePageModule),
            canLoad: [SguardGuard],
      },
      {
            path: 'lush-connect',
            loadChildren: () => import('./dashboard-streamer/lovense/lush-connect/lush-connect.module').then( m => m.LushConnectPageModule),
            canLoad: [SguardGuard, AuthGuard],
      },
      {
            path: 'lush-tutorial',
            loadChildren: () => import('./dashboard-streamer/lovense/lush-tutorial/lush-tutorial.module').then( m => m.LushTutorialPageModule),
            canLoad: [SguardGuard, AuthGuard],
      },
      {
            path: 'lush-toy-settings/:toy_id',
            loadChildren: () => import('./dashboard-streamer/lovense/lush-toy-settings/lush-toy-settings.module').then( m => m.LushToySettingsPageModule),
            canLoad: [SguardGuard, AuthGuard],
      },
      {
            path: 'lush-toy-settings',
            loadChildren: () => import('./dashboard-streamer/lovense/lush-toy-settings/lush-toy-settings.module').then( m => m.LushToySettingsPageModule),
            canLoad: [SguardGuard, AuthGuard],
      },
      {
            path: 'streamer-rules',
            loadChildren: () => import('./dashboard-streamer/help/streamer-rules/streamer-rules.module').then( m => m.StreamerRulesPageModule),
            canLoad: [SguardGuard, AuthGuard],
      },
      {
            path: 'user-deleted',
            loadChildren: () => import('./user-deleted/user-deleted.module').then( m => m.UserDeletedPageModule)
      },
      {
        path: 'signup-step0',
        loadChildren: () => import('./signup-step0/signup-step0.module').then( m => m.SignupStep0PageModule),
        canLoad: [GuestGuard],
      },
      {
        path: 'obs-video',
        loadChildren: () => import('./obs-video/obs-video.module').then( m => m.ObsVideoPageModule)
      },
      {
            path: '**',
            redirectTo: '',
            pathMatch: 'full'
      },
];

@NgModule({
      imports: [
            RouterModule.forRoot(routes, { preloadingStrategy: PreloadAllModules })
      ],
      exports: [RouterModule]
})
export class AppRoutingModule { }
