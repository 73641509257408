import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { ModalController, NavController } from '@ionic/angular';
import { RequestService } from '../_services/request.service';

@Component({
  selector: 'app-obs-video',
  templateUrl: './obs-video.page.html',
  styleUrls: ['./obs-video.page.scss'],
})
export class ObsVideoPage implements OnInit {

    @ViewChild('video', { read: ElementRef, static: true }) videoPlayer: ElementRef<HTMLVideoElement>;

  constructor(public request: RequestService, public navCtrl: NavController, private modalCtrl: ModalController) { }

  ngOnInit() {
  }

  ionViewDidEnter() {
    this.videoPlayer.nativeElement.play();
  }

  ionViewWillLeave() {
    this.videoPlayer.nativeElement.pause();
  }

  navigateToDestination() {
    this.modalCtrl.dismiss();
    this.request.users('update_obs', []).subscribe(res => {
      console.log(res);
      if(res['success']) {
        const user = JSON.parse(localStorage.getItem('userJson'));
        user.learnobs_read = '1';
        localStorage.setItem('userJson', JSON.stringify(user));
      }
    });
  }

}
