export class User {
    constructor(
      public jwtToken: string,
      public userType: number,
      public email: string,
      public userName: string,
      public tokens: number,
      public font: string,
      public level: number,
      public showTokens: number,
      public streamerVerified: number,
      public nsfw: boolean,
      public roomKey: string,
      public learnobs_read?: string
    ) {}
  }
